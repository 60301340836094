const Constants = {	
	SocialMedia: [
	    {
	        label: 'Facebook',
	        key: 'facebook',
	        weight: 2271,
	    },
	    {
	        label: 'YouTube',
	        key: 'youtube',
	        weight: 1900,
	    },
	    {
	        label: 'WhatsApp',
	        key: 'whatsapp',
	        weight: 1500,
	    },
	    {
	        label: 'Messenger',
	        key: 'fbmessenger',
	        weight: 1300,
	    },
	    {
	        label: 'WeChat',
	        key: 'wechat',
	        weight: 1083,
	    },
	    {
	        label: 'INSTAGRAM',
	        key: 'instagram',
	        weight: 1000,
	    },
	    {
	        label: 'QQ',
	        key: 'qq',
	        weight: 803,
	    },
	    {
	        label: 'QZONE',
	        key: 'qzone',
	        weight: 531,
	    },
	    {
	        label: 'TIKTOK',
	        key: 'tiktok',
	        weight: 500,
	    },
	    {
	        label: 'WEIBO',
	        key: 'weibo',
	        weight: 446,
	    },
	    {
	        label: 'Reddit',
	        key: 'reddit',
	        weight: 330,
	    },
	    {
	        label: 'Twitter',
	        key: 'twitter',
	        weight: 326,
	    },
	    {
	        label: 'Douban',
	        key: 'douban',
	        weight: 320,
	    },
	    {
	        label: 'LinkedIn',
	        key: 'linkedin',
	        weight: 303,
	    },
	    {
	        label: 'Baidu Tieba',
	        key: 'baidutieba',
	        weight: 300,
	    },
	    {
	        label: 'Skype',
	        key: 'skype',
	        weight: 300,
	    },
	    {
	        label: 'Snapchat',
	        key: 'snapchat',
	        weight: 287,
	    },
	    {
	        label: 'Viber',
	        key: 'viber',
	        weight: 260,
	    },
	    {
	        label: 'Pintrest',
	        key: 'pintrest',
	        weight: 250,
	    },
	    {
	        label: 'Line',
	        key: 'line',
	        weight: 194,
	    },
	],
}

export default Constants;

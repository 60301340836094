const token = (state = [], action) => {
    switch (action.type) {
        case 'SET_TOKEN':
            return ({
                id: action.token.id,
                guid: action.token.guid,
                userid: action.token.userid,
                profileid: action.token.profileid,
                secret: action.token.secret,
                reference: action.token.reference,
                referencetype: action.token.referencetype,
            })
        default:
            return state
    }
}

export default token
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SettingsIcon from "@material-ui/icons/Settings";
import {Paper, Divider, ListItem, ListItemText, Button, Container, IconButton, Grid} from '@material-ui/core';
import NetworkService from '../../../services/Networkservice';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'


const mapStateToProps = function (state) {
    return {
        session: state.session,
        connection: state.connection,
        contact: state.contact
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setConnection: (connection) => dispatch({
            type: 'SET_CONNECTION',
            connection: connection,
        }),
        setContact: (contact) => dispatch({
            type: 'SET_CONTACT',
            contact: contact,
        }),
        setGroup: (group) => dispatch({
            type: 'SET_GROUP',
            group: group,
        }),
    };
};

class GroupList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            search: "",
            loading: true,
        }        
    }

    componentDidMount() {
        NetworkService.getGroups(this.props.session, (profiles)=>{
            this.setState({groups: profiles, loading: false,});
        }, (err) => {
            console.log("error when fetching groups: ", err);
        });
    }
    
    _createGroup() {
        NetworkService.createEmptyProfile(this.props.session, 4)
        .then( (profile) => {
            this._editProfile(profile.id);
        })
        .catch((err) => {
            console.log("Error when creating group", err);
        });
    }

    _renderButtons() {
        return (
            <div>
                <Grid container spacing ={3} style={{marginTop: 20}}>
                <Grid item xs={9}></Grid>
                <Grid item xs={3} style={{paddingRight: 0}}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => this._createGroup()}
                        >
                            {'Create group'}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    _editProfile(profileid) {
        var state = this.state;
        state.profileid = profileid;
        this.props.history.push({
            pathname: '/editprofile',
            state: state
        })
    }

    _viewGroupMembers(guid, groupid) {
        var state = this.state;
        state.groupguid = guid;
        state.groupid = groupid;
        this.props.history.push({
            pathname: '/groupmembers',
            state: state
        })
    }

    _renderGroups() {
        var list = [];
        for (let i = 0; i < this.state.groups.length; i++) {
            if (i > 0) {
                list.push(<Divider component="li" key={"_"+this.state.groups[i].guid} />);
            }

            let displayname = this.state.groups[i].nickname;
            let iconbutton = null;

            if (this.state.groups[i].id) {
                iconbutton = (
                    <IconButton
                        aria-label="Settings"
                        onClick={() => {this._editProfile(this.state.groups[i].id)}}
                    >
                        <SettingsIcon />
                    </IconButton>
                );
            }

            list.push(
                <ListItem key={this.state.groups[i].guid} button={true}
                >
                    <ListItemText primary={displayname}
                        onClick={() => { this._viewGroupMembers(this.state.groups[i].guid, this.state.groups[i].id) }}
                    />
                    {iconbutton}
                </ListItem>
            );
        }

        if (list.length === 0) {
            list = (<p>You do not have any groups</p>);
        }
        return list;
    }

    render() {
        var groups = (<Loader
            type="ThreeDots"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={3000} //3 secs
    
         />);
        if (!this.state.loading) {
            groups = this._renderGroups();
        }
        var buttons = this._renderButtons();
        return (
            <Container component="main" align="center" maxWidth="md">
                <Paper >
                    {groups}
                </Paper>
                {buttons}
            </Container>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupList);

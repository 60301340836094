import React from 'react';
import { connect } from 'react-redux';
import NetworkService from '../services/Networkservice';
export function requireAuthentication(Component, resource, read, write) {
    
    const mapStateToProps = (state) => ({
        session: state.session
    });

    const mapDispatchToProps = (dispatch) => {
        return {
            setSession: (session) => dispatch({
                type: 'SET_SESSION',
                session: session
            }),
            dispatch: (e) => dispatch(e)
        };
    }

    class AuthenticatedComponent extends React.Component {

        componentDidMount() {
            this.checkAuth();
        }

        UNSAFE_componentWillUpdate(nextProps) {
            this.checkAuth();
        }

        checkAuth() {            
            if (!this.props.session.guid) {
                if (localStorage.getItem("Session")) {
                    NetworkService.getSession(localStorage.getItem("Session")).then( (response) => {
                        this.props.setSession(response);
                    }).catch(err=>{   
                        console.log("error in AuthMiddleware-->", err);                 
                        this.props.history.push('/login');
                    });
                } else {
                    this.props.history.push('/login');
                }
            }
        }       

        render() {
            return (
                <>
                    {this.props.session.guid 
                        ? <Component {...this.props} />
                        : null
                    }
                </>
            )
        }        
    }

    return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent);

}

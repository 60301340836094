import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helper from '../../../services/Helper';
import NetworkService from '../../../services/Networkservice';
import Constants from '../../../services/Constants';
import Container from '@material-ui/core/Container';
import { Paper, withStyles, Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Modal from 'react-modal';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Cancel";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import Desccon from '@material-ui/icons/Description';
import Homeicon from '@material-ui/icons/Home';
import Phoneicon from '@material-ui/icons/Phone';
import Emailicon from '@material-ui/icons/Email';
import Grid from '@material-ui/core/Grid';

Modal.setAppElement('#root');

const mapStateToProps = function (state) {

    return {
        session: state.session,
        profile: state.profile,
        filters: state.filters.list,
        filter: state.filter,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFilter: (filter) => dispatch({
            type: 'SET_FILTER',
            filter: filter,
        }),
        setFilters: (filters) => dispatch({
            type: 'SET_FILTERS',
            filters: filters,
        }),
        setProfile: (profile) => dispatch({
            type: 'SET_PROFILE',
            profile: profile,
        }),
    };
};

const styles = theme => ({
    root: {
        padding: theme.spacing(4, 4, 4, 4),

        direction: "column",
        alignItems: "center",
        justify: "center",
    },
    container: {
        alignItems: 'center',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        marginTop: theme.spacing(1),
        margin: 10
    },
    iconbuttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        
    },
    iconbutton: {
        marginTop: theme.spacing(.5),
    },

    choosableListItem: {
        background: '#fff'
    },
});

class EditProfile extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            profile: {
                id: this.props.session.profileid,
                givenname: "",
                surname: "",
                nickname: "",
                company: "",
                position: "",
                phone: "",
                email: "",
                street: "",
                postcode: "",
                city: "",
                country: "",
                website: "",
                profiledata: [],
                image_guid: "",
            },
            edit: null,
            editItem: null,
            editLabel: null,
            modalVisible: false,
            addDataVisible: false,
            profileid: 0,
        }
        this._copyPropsToState = this._copyPropsToState.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this._showModalEdit = this._showModalEdit.bind(this);
        this._renderPrimaryInput = this._renderPrimaryInput.bind(this);
        this._saveProfile = this._saveProfile.bind(this);
        this._handleSaveProfile = this._handleSaveProfile.bind(this);
        this._handleSaveProfileError = this._handleSaveProfileError.bind(this);
        this._renderSecondaryInputItem = this._renderSecondaryInputItem.bind(this);
        this._setLabelByType = this._setLabelByType.bind(this);
    }

    componentDidMount() {
        var profileid = 0;
        if (this.props.location.state && this.props.location.state.profileid) {
            profileid = this.props.location.state.profileid;
        } else {
            profileid = this.props.session.profileid;
        }
        this._isMounted = true;      
        this._copyPropsToState();
        if (this.props.session.guid){            
            NetworkService.getProfile(profileid, this.props.session).then((profile) => {
                if (this._isMounted) {         
                    this.setState({profile: Object.assign({}, profile)});
                }
            }).catch(()=> {
                console.log("error in refreshing profile page");
            });
        }

    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    _copyPropsToState() {
        this.setState({
            profile: this.props.profile,
            filterlist: this.props.filters,
            filter: this.props.filter,
        });
    }

    _showModalEdit(type, item = 0, label = 'PROFILE_HEADLINE_DATA') {       
        this.setState({
            edit: type,
            modalVisible: true,
            editItem: item,
            editLabel: label,
            addDataVisible: false,
        });
    }

    _handleChange(field, value) {
        let profile = this.state.profile;
        profile[field] = value;
        this.setState({ profile: profile });
    }

    _handleSaveProfile() {
        // TODO: fetch profile from server again in case of serverside changes
        this.setState({ edit: null, modalVisible: false }, function () {
        });
    }

    _handleSaveProfileError() {
        this.setState({ showActivityIndicator: false }, function () {
        });
    }

    _renderPrimaryInput(label, key, keyboardType = "default", style = {}) {
        let value = this.state.profile[key] + "";
        return (
            <TextField
                key={key}
                label={label}
                style={{ margin: 8 }}
                placeholder=""
                fullWidth
                margin="normal"
                value={value}
                onChange={(event) => {
                    this._handleChange(key, event.target.value)
                }}
                InputProps={{}}
            />
        );
    }

    _renderPrimaryView(label, key, value) {

        if (!value) {
            value = this.state.profile[key] + "";
        }
        return (
            <TextField
                key={key}
                label={label}
                style={{ margin: 8 }}
                placeholder=""
                fullWidth
                margin="normal"
                value={value}
                onChange={(event) => {
                    this._handleChange(key, event.target.value)
                }}
            />

        );
    }

    _renderPrimaryAddressView(label, key, value) {
        if(!value) {
            value = this.state.profile[key] + "";
        }
        return (
                <Grid item xs={6}>
                    <TextField 
                        key={key}
                        label={label}
                        style={{margin: 8 }}
                        placeholder=""
                        fullWidth
                        margin="normal"
                        value={value}
                        onChange={(event) => {
                            this._handleChange(key, event.target.value)
                        }}
                    />
                </Grid>
        );
    }

    _renderSecondaryViewList(label, type) {
        let rows = [];
        let item = 0;
        if (this.state.profile && this.state.profile.profiledata && this.state.profile.profiledata.length > 0) {
            rows = this.state.profile.profiledata.map((r, i) => {
                if (r.type === type) {
                    return this._renderSecondaryViewItem(label, r.type, item++);
                }
                else {
                    return null;
                }

            });
        }
        return rows;
    }
    _getProfileDataByType(type) {
        let profiledata = this.state.profile.profiledata;
        let result = [];
        if (profiledata && profiledata.length > 0) {
            for (let i = 0; i < profiledata.length; i++) {
                if (profiledata[i]['type'] === type) {
                    profiledata['row'] = i;
                    result.push(profiledata[i]);
                }
            }
        }
        return result;
    }

    _getProfileDataItemByType(type, item) {
        let result = this._getProfileDataByType(type);
        if (result.length > item) {
            return result[item];
        }
        else {
            return {
                id: null,
                row: null,
                type: type,
                value: '',
                label: ''
            };
        }
    }
    _setProfileValueByType(type, value, item) {
        let profiledata = this.state.profile.profiledata;
        let found = false;
        let iitem = 0;
        if (profiledata && profiledata.length > 0) {
            for (let i = 0; i < profiledata.length; i++) {
                if (profiledata[i].type === type) {
                    if ((!found) && (iitem === item)) {
                        profiledata[i].value = value;
                        found = true;
                    }
                    else {
                        iitem++;
                    }
                }
            }
        }
        if (found) {
            this.setState({
                profiledata: profiledata
            }, function () {
            });
        }
        else {
            this._addProfileData(type, value);
        }
    }
    _addProfileData(type, value) {
        let profile = this.state.profile;
        profile.profiledata.push({
            type: type,
            value: value,
            label: '',
        });
        this.setState({
            profile: profile
        });
    }

    _renderSecondaryInputItem(label, type, item = 0) {
        let profiledata = this._getProfileDataItemByType(type, item);
        let value = profiledata.value + "";
        return (

            <TextField
                key={type + '_' + item}
                label={label}
                style={{ margin: 8 }}
                placeholder=""
                fullWidth
                margin="normal"
                value={value}
                onChange={(event) => {
                    this._setProfileValueByType(type, event.target.value, item)
                }
                }
                InputProps={{}}
            />
        );
    }
    _setLabelByType(type) {
        if (type === 'status_note') {
            return 'Note';
        }
        else if (type === 'out_of_office') {
            return 'Out of office';
        }
        else if (type === 'phone') {
            return 'Phone';
        }
        else if (type === 'email') {
            return 'Email';
        }
        else if (type === 'website') {
            return 'Website';
        }
        else {
            for (let i = 0; i < Constants.SocialMedia.length; i++) {
                if (Constants.SocialMedia[i].key === type) {
                    return Constants.SocialMedia[i].label;
                }
            }
            return type;
        }
    }
    _renderSecondaryViewItem(label, type, item = 0, value) {
        let profiledata = {};
        if (!value) {
            profiledata = this._getProfileDataItemByType(type, item);
            value = profiledata.value + "";
        }
        if (value) {
            return (
                <TextField
                    key={type + '_' + item}
                    label={label}
                    style={{ margin: 8 }}
                    placeholder=""
                    fullWidth
                    margin="normal"
                    value={this._getProfileDataItemByType(type, item).value}
                    onChange={(event) => {
                        this._setProfileValueByType(type, event.target.value, item)
                    } }
                    InputProps={{}}
                />
            );
        }
        else {
            return null;
        }
    }
    _renderName() {
        let displayname = Helper.concatObjectData(this.state.profile, ['givenname', 'surname'], " ");      
        if (!displayname && !this.state.profile.nickname) {
            displayname = 'Click to edit name';
        }
        if ((this.state.filter != null && this.state.filter.guid) || this.state.profile.type === 7 || this.state.profile.type === 4) {
            return (
                <div>
                    <div icon itemDivider>
                        <div>
                            <Phoneicon />
                        </div>
                        <div>
                            <div>{"PROFILE_HEADLINE_NAME"}</div>
                        </div>
                    </div>
                    {this.state.profile.type === 4 || this._renderPrimaryView('Given name', 'givenname')}
                    {this.state.profile.type === 4 || this._renderPrimaryView('Sur name', 'surname')}
                    {this._renderPrimaryView('Nick name', 'nickname')}
                </div>
            );
        }
        else {
            return (
                <div style={styles.contactLineName}>
                    <div onClick={() => { this._showModalEdit('name') }}>
                        <p style={styles.contactLineNameText}>{displayname}</p>
                        <p style={styles.contactLineNicknameText}>{this.state.profile.nickname}</p>
                    </div>
                </div>
            );
        }
    }

    _renderStatus() {
        let rows = [];
        let statusrow = this._renderSecondaryViewItem('Status note', 'status_note');
        let ooorow = this._renderSecondaryViewItem('Out of office', 'out_of_office');
        if (statusrow != null) {
            rows.push(statusrow);
        }
        if (ooorow != null) {
            rows.push(ooorow);
        }
        if (!rows.length) {
            return;
        }
        return (
            <div>
                {rows}
            </div>
        );
    }

    _renderCompany() {

        let company = Helper.concatObjectData(this.state.profile, ['company', 'position'], ", ");
        return (
            <div>
                {this._renderPrimaryView('Company', 'company', company)}
            </div>

        );
    }
    _renderPhone() {
        return (
            <div>
                {this._renderPrimaryView('Phone', 'phone')}
                {this._renderSecondaryViewList('Phone', 'phone', 'phone-pad')}
            </div>
        );
    }
    _renderEmail() {
        return (
            <div>
                <div id="1">
                    {this._renderPrimaryView('Email', 'email')}
                </div>
                <div id="2">
                    {this._renderSecondaryViewList('Email', 'email', 'email-address')}
                </div>
            </div>
        );
    }

    _renderAddress() {
        let address = Helper.concatObjectData(this.state.profile, ['street', 'postcode', 'city', 'country'], ", ");
        // if (!address.length) {
        //     return;
        // }
        // else if (this.state.filter.guid) {
            return (
                <div>
                    <Grid container spacing={3}>
                        {this._renderPrimaryAddressView('Street', 'street')}
                        {this._renderPrimaryAddressView('Post code', 'postcode')}
                        {this._renderPrimaryAddressView('City', 'city')}
                        {this._renderPrimaryAddressView('Country', 'country')}
                    </Grid>
                </div>
            );
        // }
        // else {
        //     return (
        //         <div>
        //             {this._renderPrimaryAddressView('Address', 'address', address)}
        //         </div>
        //     );
        // }
    }

    _renderSocialMedia() {
        let rows = [];
        let row = null;
        if (this.state.profile.website) {
            row = this._renderPrimaryView('Website', 'website');
            rows.push(row);
        }
        for (let i = 0; i < Constants.SocialMedia.length; i++) {
            let media = Constants.SocialMedia[i];
            row = this._renderSecondaryViewItem(media.label, media.key);
            if (row) {
                rows.push(row);
            }
        }
        if (rows.length) {
            return (
                <div>
                    {rows}
                </div>
            );
        }
        else {
            return null;
        }
    }
    _deleteProfile() {
        NetworkService.deleteProfile(this.props.session, this.state.profile.id)
        .then(()=>{
            // alert("Deleted");
            this.props.history.goBack();
        })
        .catch((err)=>{
            console.log(err);
        });
    }
    _showAddData() {
        this.setState({
            addDataVisible: true
        });
    }

    _hideAddData() {
        this.setState({
            addDataVisible: false
        });
    }

    _renderAddData() {
        const { classes } = this.props;
        let deleteButton = null;
        let addProfiledataButton = null;
        let saveButton = null;

        if (this.state.profile.type === 7 || this.state.profile.type === 4) {
            deleteButton = (
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.button}
                        onClick={() => this._deleteProfile()}
                    >
                        {'Delete'}
                    </Button>);
        }
        if (this.state.profile.type !== 4) {
            addProfiledataButton = (
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.button}
                        onClick={() => this._showAddData()}
                    >
                        {'Add profiledata'}
                    </Button>
            );
        }

        saveButton = (
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    onClick={() => this._saveModal()}
                >
                    {'Save profile'}
                </Button>
        );

        if (this.state.filter != null && (this.state.filter.guid || this.state.modalVisible)) {
            return null;
        }
        else {
            return (
                <div className={classes.buttons}>
                    {saveButton}
                    {addProfiledataButton}
                    {deleteButton}
                </div>
            );

        }
    }
    _renderAddDataView() {
        const { classes } = this.props;
        let socialmedia = [];
        for (let i = 0; i < Constants.SocialMedia.length; i++) {
            let media = Constants.SocialMedia[i];
            let pdata = this._getProfileDataItemByType(media.key, 0);
            if (!pdata.value.length) {
                socialmedia.push(
                    <ListItem key={media.key}
                        button
                        onClick={() => { this._showModalEdit(media.key) }}
                    >
                        <ListItemText secondary={media.label} />
                    </ListItem>

                );
            }
        }
        if (socialmedia.length) {
            socialmedia.unshift(
                <div>
                    <ListItem>
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Social" />
                    </ListItem>
                    <Divider component="li" />
                </div>
            );
        }

        let statusnotes = [];
        let status = this._getProfileDataItemByType('status_note', 0);
        if (!status.value.length) {
            statusnotes.push(
                <List>
                    <ListItem
                        button
                        onClick={() => { this._showModalEdit('status_note') }}
                    >

                        <ListItemText secondary={'Add status note'} />
                    </ListItem>
                </List >
            );
        }
        status = this._getProfileDataItemByType('out_of_office', 0);
        if (!status.value.length) {
            statusnotes.push(
                <List>
                    <ListItem
                        button
                        onClick={() => { this._showModalEdit('out_of_office') }}
                    >
                        <ListItemText secondary={'Add out of office message'} />
                    </ListItem>
                </List >
            );
        }
        if (statusnotes.length) {
            statusnotes.unshift(
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <Desccon />
                        </ListItemIcon>
                        <ListItemText primary={'Status'} />
                    </ListItem>
                    <Divider component="li" />
                </List >

            );
        }

        let address = Helper.concatObjectData(this.state.profile, ['street', 'postcode', 'city', 'country'], ", ");
        if (!address.length) {
            address = (
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <Homeicon />
                        </ListItemIcon>
                        <ListItemText primary={'Address'} />
                    </ListItem>
                    <Divider component="li" />
                    <ListItem button onClick={() => { this._showModalEdit('address') }}>
                        <ListItemText secondary={'Add Address'} />
                    </ListItem>
                </List >
            );
        }
        else {
            address = null;
        }


        let phonelist = this._getProfileDataByType('phone');
        let emaillist = this._getProfileDataByType('email');

        return (
            <Container maxWidth="md" component="main" >
                <Paper className={classes.root}>

                    {statusnotes}
                    <List >
                        <ListItem>
                            <ListItemIcon>
                                <Phoneicon />
                            </ListItemIcon>
                            <ListItemText primary={'Phone'} />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem button onClick={() => { this._showModalEdit('phone_list', (phonelist.length)) }} className={classes.choosableListItem}>
                            <ListItemText secondary={'Add phonenumber'} />
                        </ListItem>
                    </List >

                    <List >
                        <ListItem>
                            <ListItemIcon>
                                <Emailicon />
                            </ListItemIcon>
                            <ListItemText primary={'Email'} />
                        </ListItem>
                        <Divider component="li" />
                        <ListItem button onClick={() => { this._showModalEdit('email_list', (emaillist.length)) }}>
                            <ListItemText secondary={'Add Email'} />
                        </ListItem>
                        
                    </List >
                    {address}
                    {socialmedia}
                    <div className={classes.buttons}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.button}
                            onClick={() => this._hideAddData()}
                        >
                            {'Cancel'}
                        </Button>
                    </div>
                </Paper>

            </Container>
        );
    }

    closeModal() {
        this.setState({ modalVisible: false });
    }

    _renderModalContent() {
        if (this.state.edit === 'name') {
            return (
                <div>
                    {this._renderPrimaryInput('Given name', 'givenname')}
                    {this._renderPrimaryInput('Sur name', 'surname')}
                    {this._renderPrimaryInput('Nick name', 'nickname')}
                </div>
            );
        }
        if (this.state.edit === 'company') {
            return (
                <div>
                    {this._renderPrimaryInput('Company', 'company')}
                    {this._renderPrimaryInput('Position', 'position')}

                </div>
            );
        }
        else if (this.state.edit === 'phone') {
            return (
                <div>
                    {this._renderPrimaryInput('Phone', 'phone', 'number-pad')}
                </div>
            );
        }
        else if (this.state.edit === 'phone_list') {
            return (
                <div>
                    {this._renderSecondaryInputItem('Phone', 'phone', this.state.editItem)}
                </div>
            );
        }
        else if (this.state.edit === 'email') {
            return (
                <div>
                    {this._renderPrimaryInput('Email', 'email', 'email-address')}
                </div>
            );
        }
        else if (this.state.edit === 'email_list') {
            return (
                <div>
                    {this._renderSecondaryInputItem('Email', 'email', this.state.editItem)}
                </div>
            );
        }
        else if (this.state.edit === 'street') {
            return (
                <div>
                    {this._renderPrimaryInput('Street', 'street')}
                </div>
            );
        }
        else if (this.state.edit === 'postcode') {
            return (
                <div>
                    {this._renderPrimaryInput('Post code', 'postcode')}
                </div>
            );
        }
        else if (this.state.edit === 'city') {
            return (
                <div>
                    {this._renderPrimaryInput('City', 'city')}
                </div>
            );
        }
        else if (this.state.edit === 'country') {
            return (
                <div>
                    {this._renderPrimaryInput('Country', 'country')}
                </div>
            );
        }
        else if (this.state.edit === 'address') {
            return (
                <div>
                    {this._renderPrimaryInput('Street', 'street')}
                    <div style={{ flexDirection: 'row' }}>
                        {this._renderPrimaryInput('PC', 'postcode', null, { flex: 1 })}
                        {this._renderPrimaryInput('City', 'city', null, { flex: 3 })}
                    </div>
                    {this._renderPrimaryInput('Country', 'country')}
                </div>
            );
        }
        else if (this.state.edit === 'filtername') {
            return (
                <div>
                    <label>{'PROFILE_FILTER_NAME'}</label>
                    <input onChange={(change) => this._handleFilterNameChange(change)} value={this.state.filter.name} />
                </div>
            );
        }
        else if (this.state.edit === 'givenname') {
            return (
                <div>
                    {this._renderPrimaryInput('Given name', 'givenname')}
                </div>
            );
        }
        else if (this.state.edit === 'website') {
            return (
                <div>
                    {this._renderPrimaryInput('Website', 'website')}
                </div>
            );
        }
        else if (this.state.edit === 'surname') {
            return (
                <div>
                    {this._renderPrimaryInput('Sur name', 'surname')}
                </div>
            );
        }
        else if (this.state.edit === 'nickname') {
            return (
                <div>
                    {this._renderPrimaryInput('Nick name', 'nickname')}
                </div>
            );
        }
        else {
            return (
                <div>
                    {this._renderSecondaryInputItem(this._setLabelByType(this.state.edit), this.state.edit)}
                </div>
            );
        }
    }
    _saveProfile() {
        let profile = this.state.profile;
        delete profile['image'];
        NetworkService.updateProfile(
            profile,
            this.props.session,
            this._handleSaveProfile,
            this._handleSaveProfileError
        );
    }

    _saveModal() {
        if (this.state.edit === 'filtername') {
            this._saveFilter();
        }
        else {
            this._saveProfile();
        }

    }
    _renderView() {
        const { classes } = this.props;
        return (
            <Container maxWidth="md" component="main" >
                <Modal
                    isOpen={this.state.modalVisible}
                    onRequestClose={this.closeModal}
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            width: '30%',
                            height: '35%',
                            transform: 'translate(-50%, -50%)',
                        }
                    }}
                    contentLabel="Example Modal"
                >

                    <div>
                        <div className={classes.iconbuttons} style={{
                            position: 'absolute',
                            top: 0,
                            right: 0
                        }}>
                            <IconButton
                                data-dismiss="modal"
                                aria-label="Close"
                                className={classes.iconbutton}
                                onClick={this.closeModal}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>

                        <div>
                            {this._renderModalContent()}
                        </div>
                        <div className={classes.buttons} style={{
                            position: 'absolute',
                            bottom: 0,
                            left: '30%',
                            width: 'fit-content'
                        }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}
                                onClick={() => this._saveModal()}
                            >
                                {'Save profiledata'}
                            </Button>
                        </div>
                    </div>

                </Modal>
                <Paper className={classes.root}>
                    {this._renderName()}
                    {this._renderStatus()}
                    {this._renderPhone()}
                    {this._renderCompany()}
                    {this._renderEmail()}
                    {this._renderAddress()}
                    {this._renderSocialMedia()}
                    {this._renderAddData()}
                </Paper>
            </Container>
        )
    }
    render() {
        if (this.state.addDataVisible && !this.state.filter.guid) {
            return this._renderAddDataView();
        }
        else {
            return this._renderView();
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditProfile))

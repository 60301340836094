const share = (state = [], action) => {
    switch (action.type) {
        case 'SET_SHARE':
            return ({
                guid: action.share.guid,
                name: action.share.name,
                sharename: action.share.sharename,
                profile: action.share.profile,
                connection: action.share.connection,
                active: action.share.active,
                single: action.share.single,
                public: action.share.public,
                auto_accept: action.share.auto_accept,
                authenticate: action.share.authenticate,
                filterid: action.share.filterid,
                sharedata: action.share.sharedata,
                expires: action.share.expires
            })
        default:
            return state
    }
}

export default share
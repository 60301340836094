const profile = (state = [], action) => {    
    switch (action.type) {
        case 'SET_PROFILE':
            return ({
                id: action.profile.id,
                displayname: action.profile.displayname,
                givenname: action.profile.givenname,
                surname: action.profile.surname,
                nickname: action.profile.nickname,
                company: action.profile.company,
                position: action.profile.position,
                phone: action.profile.phone,
                email: action.profile.email,
                street: action.profile.street,
                postcode: action.profile.postcode,
                city: action.profile.city,
                country: action.profile.country,
                website: action.profile.website,
                image: action.profile.image,
                profiledata: action.profile.profiledata,
                image_guid: action.profile.image_guid,
                deleteprofile: action.profile.deleteprofile,
            })
        default:
            return state
    }
}

export default profile
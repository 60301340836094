const request = (state = [], action) => {
    switch (action.type) {
        case 'SET_REQUEST':
            return ({
                guid: action.request.guid,
                shareguid: action.request.shareguid,
                requestname: action.request.requestname,
                profile: action.request.profile,
                active: action.request.active,
                filterid: action.request.filterid,
            })
        default:
            return state
    }
}

export default request
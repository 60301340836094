import React, { Component } from 'react';
import Login from './components/login';
import { connect, Provider } from 'react-redux'
import { Router, Route} from "react-router-dom";
import Nav from './Nav';
import Start from './components/start/Start';
import Profile from './components/common/profile/Profile';
import VirtualProfiles from './components/common/virtualprofiles/VirtualProfiles';
import Contacts from './components/common/contacts/Contacts';
import ShareView from './components/share/shareview/ShareView';
import ShareHistory from './components/share/sharehistory/ShareHistory';
import GroupList from './components/group/grouplist/GroupList';
import GroupMembers from './components/group/groupmembers/GroupMembers';
import About from './components/common/about/About';
import { requireAuthentication } from './middleware/AuthMiddleware';

const mapStateToProps = function (state) {
  return {
      session: state.session,       
      user: state.user,      
  }
};


const mapDispatchToProps = (dispatch) => {
  return {
      setSession: (session) => dispatch({
          type: 'SET_SESSION',
          session: session,
      }),
      setUser: (user) => dispatch({
          type: 'SET_USER',
          user: user,
      }),
      setToken: (token) => dispatch({
          type: 'SET_TOKEN',
          token: token,
      })     
  };
};

class App extends Component {
  render() {
    return (
      <div className="d-flex" id="wrapper" >                   
        <Provider store={this.props.store}>
       
        <Router history={this.props.history}>
        {this.props.session.guid && <Nav history={this.props.history}/>}
            <Route path="/login" exact component={Login} />     
            <Route path="/" exact component={requireAuthentication(Start)} />                 
            <Route path="/profile" exact component={requireAuthentication(Profile)} />      
            <Route path="/editprofile" exact component={requireAuthentication(Profile)} />
            <Route path="/contacts" exact component={requireAuthentication(Contacts)} />
            <Route path="/virtualprofiles" exact component={requireAuthentication(VirtualProfiles)} />
            <Route path="/grouplist" exact component={requireAuthentication(GroupList)} />     
            <Route path="/groupmembers" exact component={requireAuthentication(GroupMembers)} />
            <Route path="/shareview" component={requireAuthentication(ShareView)}/>   
            <Route path="/sharehistory" component={requireAuthentication(ShareHistory)} />
            <Route path="/about" exact component={About} />                 
            <Route path="/logout" exact component={Login} />
        </Router>
        </Provider>
      </div>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);

const Helper = { 
    concatObjectData: function(object, keys = [], padding = " "){
        // This function concats selected keys of an object with padding in between
        let parts = [];
        for (var i=0; i<keys.length; i++){
            let key = keys[i];
            if (object[key] && object[key].length>0){
                parts.push(object[key]);
            }
        }
        if (parts.length){
            return parts.join(padding);
        }
        else{
            return '';
        }
    },
    looksLikeAnEmail: function(email){
          // TODO: this could be better :)
          if (email.indexOf('@')>0){
              return true;
          }
          else{
              return false;
          }
    },
    trimChar: function(string, charToRemove) {
        while(string.charAt(0)==charToRemove) {
            string = string.substring(1);
        }

        while(string.charAt(string.length-1)==charToRemove) {
            string = string.substring(0,string.length-1);
        }

        return string;
    },
    empty: function(e) {
        switch (e) {
            case "":
            case 0:
            case "0":
            case null:
            case false:
            case Object.entries(e).length === 0 && e.constructor === Object:
            case e.length == 0:
            case typeof this == "undefined":
                return true;
            default:
                return false;
        }
    },
    validateEmail: function(email) {
    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
    },
    validatePhone: function(phone) {
        let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        return regex.test(phone);
    },
    findDuplicates: function(arr) {
        let sorted_arr = arr.slice().sort(); // You can define the comparing function here.
        // JS by default uses a crappy string compare.
        // (we use slice to clone the array so the
        // original array won't be modified)
        let results = [];
        for (let i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1] == sorted_arr[i]) {
                results.push(sorted_arr[i]);
            }
        }
        return results;
    }
}
export default Helper;

//const serverAddress = "https://bpi.kforcontact.com/"
const serverAddress = "https://api.kforcontact.com/"
const NetworkService = {
    logout: function (session, callback, errCallback) {
        fetch(serverAddress + "v1/logout/", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "session": session.guid,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({})
        })
        .then(response => {
            return NetworkService.handleJsonResponse( response )
        })
        .then(responseJson => {
            callback(responseJson);
        })
        .catch(error => {
            errCallback(error);
        });
    },

    login: function (user, callback, errCallback) {
        fetch(serverAddress + "v1/login/", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: user.username,
                password: user.password,
                email: user.email
            })
        })
        .then(response => {
            return NetworkService.handleJsonResponse( response )
        })
        .then(responseJson => {
            callback(responseJson);
        })
        .catch(error => {
            errCallback(error);
        });
    },

    loginByToken: function (token, user, callback, errCallback) {
        fetch(serverAddress + "v1/login/" + token, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: user.email,
                secret: user.secret
            })
        })
        .then(response => {
            return NetworkService.handleJsonResponse(response)
        })
        .then(responseJson => {
            callback(responseJson);
        })
        .catch(error => {
            errCallback(error);
        });
    },


    handleJsonResponse: function( response ){
        var promise = new Promise( (resolve, reject) => {
            response.json()
            .then( responseJson => {
                if (response.ok){
                    resolve( responseJson );
                }                else{
                    // all KapiErrors should end up here
                    reject( responseJson );
                }
            })
            .catch(error => {
                reject( error )
            });
        });
        return promise;
    },
    handleEmptyResponse: function( response ){
        var promise = new Promise( (resolve, reject) => {
            if (response.ok){
                // status in the range 200-299
                resolve( {} );
            }
            else{
                // all KapiErrors should end up here
                reject( {} );
            }
        });
        return promise;
    },
    getProfile: function (profileid, session) {

        var promise = new Promise(function (resolve, reject) {
            fetch(serverAddress + "v1/profile/" + profileid, {
                method: "GET",
                headers: {
                    "session": session.guid
                }
            })
            .then(response => {
                return NetworkService.handleJsonResponse( response )
            })
            .then(responseJson => {
                resolve(responseJson);
            })
            .catch(error => {
                reject(error);
            });
        });
        return promise;
    },
    getProfiles: function (session) {
        var promise = new Promise(function (resolve, reject) {
            fetch(serverAddress + "v1/profile/", {
                method: "GET",
                headers: {
                    "session": session.guid
                }
            })
            .then(response => {
                return NetworkService.handleJsonResponse( response )
            })
            .then(responseJson => {
                resolve(responseJson);
            })
            .catch(error => {
                reject(error);
            });
        });
        return promise;
    },
    createEmptyProfile: function (session, type) {
        var promise = new Promise(function (resolve, reject) {
            var profile = {
                type: type,
            };
            fetch(serverAddress + "v1/profile/", {
                method: "POST",
                headers: {
                    "session": session.guid,
                    "Accept": "application/json",
                    "Content-Type": "application/json"

                },
                body: JSON.stringify(profile)
            })
            .then(response => {
                return NetworkService.handleJsonResponse( response )
            })
            .then(responseJson => {
                resolve(responseJson);
            })
            .catch(error => {
                reject(error);
            });
        });
        return promise;
    },
    deleteProfile: function (session, profileid) {
        var promise = new Promise(function (resolve, reject) {
            fetch(serverAddress + "v1/profile/"+profileid, {
                method: "DELETE",
                headers: {
                    "session": session.guid,
                    "Accept": "application/json",
                    "Content-Type": "application/json"

                },
            })
            .then(response => {
                return NetworkService.handleJsonResponse( response )
            })
            .then(responseJson => {
                resolve(responseJson);
            })
            .catch(error => {
                reject(error);
            });
        });
        return promise;
    },
    getConnections: function (session, callback, errCallback) {
            fetch(serverAddress + "v1/connection/", {
                method: "GET",
                headers: {
                    "session": session.guid,
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                return NetworkService.handleJsonResponse( response )
            })
            .then(responseJson => {
                callback(responseJson);
            })
            .catch(error => {
                errCallback(error);
            });
    },
    getConnection: function (guid, session) {
        var promise = new Promise(function (resolve, reject) {
            fetch(serverAddress + "v1/connection/"+guid, {
                method: "GET",
                headers: {
                    "session": session.guid,
                    "Accept": "application/json",
                    "Content-Type": "application/json"

                },
            })
            .then(response => {
                return NetworkService.handleJsonResponse( response )
            })
            .then(responseJson => {
                resolve(responseJson);
            })
            .catch(error => {
                reject(error);
            });
        });
        return promise;
    },
    getContacts: function (session, callback) {
        var promise = new Promise(function (resolve, reject) {
            NetworkService.getConnections(session, (connections) => {
                var promises = [];
                for (var i = 0; i < connections.length; i++) {
                    if (connections[i].profiletype === 1 && connections[i].profile)
                        promises.push(NetworkService.getConnection(connections[i].guid, session));
                }

                Promise.all(promises).then(function (values) {
                    resolve(values);
                }).catch(error=> {
                    console.log(error);
                })
            }, (err) => {
                console.log(err);
            });
        });
        return promise;
    },
    getGroups: function (session, callback, errCallback) {
            NetworkService.getConnections(session, function (connections) {
                var users = [];
                var promises = [];
                connections.forEach(function (connection) {
                    if (connection.recipient_active === 1 && connection.profiletype === 4){
                        promises.push(NetworkService.getConnection(connection.guid, session));
                    }
                });

                Promise.all(promises).then(function (values) {
                    for (var i = 0; i < values.length; i++) {
                        values[i].profile.guid = values[i].guid;
                        values[i].profile.filterid = values[i].filterid;
                        users.push(values[i].profile);
                    }
                    callback(users);
                }).catch(
                    (reason) => {
                        errCallback(reason);
                    }
                );
            }, function (err) {
                errCallback(err);
            });
    },
    getGroup: function (guid, session) {
        var promise = new Promise(function (resolve, reject) {
            NetworkService.getConnection(guid, session).then((group) => {
            var promises = [];
                var trimConnectionList = [];
                for (var i = 0; i < group.connectionlist.length; i++) {
                    if (group.connectionlist[i].recipient_active === 1){
                        promises.push(NetworkService.getConnection(group.connectionlist[i].guid, session));
                        trimConnectionList.push(group.connectionlist[i]);
                    }
                }
                // this removes inactive connections
                group.connectionlist = trimConnectionList;
                Promise.all(promises).then(function (values) {
                    for (var i = 0; i < group.connectionlist.length; i++) {
                        group.connectionlist[i].profile = values[i].profile;
                        group.connectionlist[i].profile.displayname =
                            values[i].profile.givenname +
                            (values[i].profile.surname ? (" " + values[i].profile.surname) : "") +
                            (values[i].profile.company ? (", " + values[i].profile.company) : "");
                        group.connectionlist[i].connectiondata = values[i].connectiondata;
                        group.connectionlist[i].direct_guid = values[i].direct_guid;
                    };
                    resolve(group);
                }).catch(
                    (reason) => {
                        reject(reason);
                    }
                );
            }).catch(err => {
                reject(err);
            })
        });
        return promise;
    },
    deleteConnection: function (session, guid) {
        var promise = new Promise(function (resolve, reject) {
            fetch(serverAddress + "v1/connection/"+guid, {
                method: "DELETE",
                headers: {
                    "session": session.guid,
                    "Accept": "application/json",
                    "Content-Type": "application/json"

                },
            })
            .then(response => {
                return NetworkService.handleJsonResponse( response )
            })
            .then(responseJson => {
                resolve(responseJson);
            })
            .catch(error => {
                reject(error);
            });
        });
        return promise;
    },
    createConnection: function (session, profileguid, groupid) {
        var promise = new Promise(function (resolve, reject) {
            fetch(serverAddress + "v1/connection/"+profileguid+"/"+groupid, {
                method: "POST",
                headers: {
                    "session": session.guid,
                    "Accept": "application/json",
                    "Content-Type": "application/json"

                },
            })
            .then(response => {
                return NetworkService.handleJsonResponse( response )
            })
            .then(responseJson => {
                resolve(responseJson);
            })
            .catch(error => {
                reject(error);
            });
        });
        return promise;
    },
    getFilters: function (session, callback, errCallback) {
        fetch(serverAddress + "v1/filter/", {
            method: "GET",
            headers: {
                "session": session.guid
            }
        })
        .then(response => {
            return NetworkService.handleJsonResponse( response )
        })
        .then(responseJson => {
            callback(responseJson);
        })
        .catch(error => {
            errCallback(error);
        });
    },
    getUser: function (userid, session, callback, errCallback) {
        fetch(serverAddress + "v1/user/" + userid, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "session": session.guid,
                "Content-Type": "application/json"
            }
        })
        .then(response => {
            return NetworkService.handleJsonResponse( response )
        })
        .then(responseJson => {
            callback(responseJson);
        })
        .catch(error => {
            errCallback(error);
        });
    },
    updateProfile: function (profile, session, callback, errCallback) {
        fetch(serverAddress + "v1/profile/" + profile.id, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "session": session.guid,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(profile)
        })
        .then(response => {
            return NetworkService.handleJsonResponse( response )
        })
        .then(responseJson => {
            callback(responseJson);
        })
        .catch(error => {
            errCallback(error);
        });
    },
    createShare: function (share, session, callback, errCallback) {
        fetch(serverAddress + "v1/share/", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "session": session.guid,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(share)
        })
        .then(response => {
            return NetworkService.handleJsonResponse( response )
        })
        .then(responseJson => {
            callback(responseJson);
        })
        .catch(error => {
            errCallback(error);
        });
    },
    updateShare: function (share, session, callback, errCallback) {
        fetch(serverAddress + "v1/share/" + share.guid, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "session": session.guid,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(share)
        })
        .then(response => {
            return NetworkService.handleJsonResponse( response )
        })
        .then(responseJson => {
            callback(responseJson);
        })
        .catch(error => {
            errCallback(error);
        });
    },
    deleteShare: function (guid, session, callback, errCallback) {
        fetch(serverAddress + "v1/share/" + guid, {
            method: "DELETE",
            headers: {
                "session": session.guid
            }
        })
        .then(response => {
            return NetworkService.handleEmptyResponse( response )
        })
        .then(responseJson => {
            callback(responseJson);
        })
        .catch(error => {
            errCallback(error);
        });
    },
    getShare: function (guid, session, callback, errCallback, retry) {
        fetch(serverAddress + "v1/share/" + guid, {
            method: "GET",
            headers: {
                "session": session.guid
            }
        })
        .then(response => {
            return NetworkService.handleJsonResponse( response )
        })
        .then(responseJson => {
            callback(responseJson);
        })
        .catch(error => {
            if (retry>0){
                // retry call again with 200 ms delay
                retry--;
                setTimeout(function(){
                    NetworkService.getShare(guid,session,callback,errCallback,retry);
                }, 200 );
            }
            else{
                errCallback(error);
            }
        });
    },
    getShares: function (session, callback, errCallback) {
        fetch(serverAddress + "v1/share/", {
            method: "GET",
            headers: {
                "session": session.guid
            }
        })
        .then(response => {
            return NetworkService.handleJsonResponse( response )
        })
        .then(responseJson => {
            callback(responseJson);
        })
        .catch(error => {
            errCallback(error);
        });
    },
    getShareConnections: function (guid, session, callback, errCallback) {
        fetch(serverAddress + "v1/connection/?shareguid="+guid, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "session": session.guid,
                "Content-Type": "application/json"
            }
        })
        .then(response => {
            return NetworkService.handleJsonResponse( response )
        })
        .then(responseJson => {
            callback(responseJson);
        })
        .catch(error => {
            errCallback(error);
        });
    },
    getSession: function (session) {
        var promise = new Promise(function (resolve, reject) {
            fetch(serverAddress + "v1/session/" + session, {
                method: "GET",
                headers: {
                    "session": session
                }
            })
            .then(response => {
                return NetworkService.handleJsonResponse( response )
            })
            .then(responseJson => {
                resolve(responseJson);
            })
            .catch(error => {
                reject(error);
            });
        });
        return promise;
    },
    getImageUrl: function(image_guid) {
        return serverAddress + 'v1/image/' + image_guid;
    },
    getConnectionReport: function (session, guid) {
        var promise = new Promise(function (resolve, reject) {
            fetch(serverAddress + "v1/connection/" + guid + '/report', {
                method: "POST",
                headers: {
                    "session": session.guid,
                    "Content-Type": "application/xlsx"

                },
                responseType: 'blob'
            })
            .then(response => {
              return response.blob();
            })
            .then(blob => {
                resolve(blob);
            })
            .catch(error => {
                reject(error);
            });
        });
        return promise;
    },
}

export default NetworkService;

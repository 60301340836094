const session = (state = [], action) => {
    switch (action.type) {
        case 'SET_SESSION':
            return ({
                id: action.session.id,
                userid: action.session.userid,
                guid: action.session.guid,
                profileid: action.session.profileid
            })
        default:
            return state
    }
}

export default session
const user = (state = [], action) => {
    switch (action.type) {
        case 'SET_USER':
            return ({
                id: action.user.id,
                username: action.user.username,
                profileid: action.user.profileid,
                license_accepted: action.user.license_accepted,
                license_accepted_date: action.user.license_accepted_date,

            })
        default:
            return state
    }
}

export default user
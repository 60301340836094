import React, { Component } from 'react';
import { connect } from 'react-redux'
import Container from '@material-ui/core/Container';
import {withStyles, Grid, Button } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import image from '../../images/gradiant-large.jpg';
import logo from '../../images/k_logo_trans_white_large.png';
import CreateIcon from '@material-ui/icons/Share';
import NetworkService from '../../services/Networkservice';
import Moment from 'moment';
import style from './Start.css';

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
};

const mapDispatchToProps = (dispatch) => {
    return {        
        setShare: (share) => dispatch({
            type: 'SET_SHARE',
            share: share,
        }),
    };
};

const theme = createMuiTheme({
    overrides: {
        // Name of the component ⚛️ / style sheet
        MuiButton: {
            // Name of the rule
            text: {
                // Some CSS
                //background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                borderRadius: 3,
                border: 0,
                color: 'white',
                height: 48,
                padding: '0 30px',
                boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            },
        },
    },
});

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,            
        },
        ul: {
            margin: 0,
            padding: 0,
        },
        li: {
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundImage: `url(${image})`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
        backgroundImage: 'rgb(0,0,0,0)',
        color: 'white',
        position: 'relative',
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },  
    media: {
        paddingTop: "56.25%"
    },
    content: {
        textAlign: "left",
        padding: theme.spacing(3)
    },
    heading: {
        fontWeight: "bold"
    },
    subheading: {
        lineHeight: 1.8
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    card: {
        backgroundColor: 'rgba(0,0,0,0)',
        borderRadius: 0,
        boxShadow: "none",
        color: "white",
        maxWidth: 350,
        marginLeft: 125,
        
        
    },
    cardRight: {
        backgroundColor: 'rgba(0,0,0,0)',
        borderRadius: 0,
        boxShadow: "none",
        color: "white",
        maxWidth: 350,
        marginRight: 125,
        float: 'right',
        marginBottom: 40
        
    },

    title: {
        fontSize: 32,
    },
    body: {
        fontSize: 15,
        fontWeight: 'light'
    },
    imageCenter: {
        position: 'fixed',
        left: '47%',
        top: '40%'

      },
      
      shareButton: {
        marginTop: 15,
      },
    
});

class Start extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterid: null
        }
        this._quickShare = this._quickShare.bind(this);
        this._handleShare = this._handleShare.bind(this);
        this._handleFailedShare = this._handleFailedShare.bind(this);
    }

    _quickShare(){
        NetworkService.createShare(
            {
                sharename: Moment().format("DD/MM/YYYY hh:mm a"),
                active: true,
                profileid: this.props.session.profileid,
                filterid: this.state.filterid,
                public: false,
                single: true,
                auto_accept: true,
                expires: null
            },
            this.props.session,
            this._handleShare,
            this._handleFailedShare,
        );
    }

    _handleShare(share) {
        this.props.setShare(share);
        this.props.history.push("/ShareView")
    }

    _handleFailedShare(error) {
       console.log("error---", error);
       
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment >          
                {/* Hero unit */}
                <Container component="main" className={classes.heroContent} style={{paddingTop: 5}}>

                       
                    
                    {/* End hero unit */}               
                        <img src={logo} alt="Logo" className="center-logo" />

                     
                    </Container>                    
                    <div style={{clear: 'both'}}>

                    </div>

            </React.Fragment>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Start))

const connection = (state = [], action) => {    
    switch (action.type) {
        case 'SET_CONNECTION':
            return ({
                id: action.connection.id,
                guid: action.connection.guid,
                direct_guid: action.connection.direct_guid,
                profileid: action.connection.profileid,
                recipientid: action.connection.recipientid,
                profile: action.connection.profile,
                filterid: action.connection.filterid,
                connectiondata: action.connection.connectiondata || [],
            })
        default:
            return state
    }
}

export default connection
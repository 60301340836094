import React, { Component } from 'react';
import Helper from '../../../services/Helper';
import NetworkService from '../../../services/Networkservice';
import Modal from 'react-modal';
import ContactInfo from '../contactinfo';
import DeleteIcon from "@material-ui/icons/Cancel";
import { Container, Paper, ListItem, ListItemText, Divider, IconButton, List, Button } from '@material-ui/core';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import logo from '../../../images/k_logo_svg.svg';
import whitelogo from '../../../images/virtuallogo.svg';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import ListIcon from '@material-ui/icons/List';
import PaginationTable from '../elements/PaginationTable';
import Grid from '@material-ui/core/Grid';
import { CSVLink } from "react-csv";
import CSVReader from 'react-csv-reader'

Modal.setAppElement('#root');

class Contacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: [],
            profiles: [],
            modalVisible: false,
            showcontact: null,
            loading: true,
            virtualModalVisible: false,
            csvData: [],
            warnings: {},
            errors: [],
            fileUploaded: false,
        }
        this._closeModal = this._closeModal.bind(this);
        this._closeVirtualModal = this._closeVirtualModal.bind(this);
        this._openImportModal = this._openImportModal.bind(this);
    }

    _closeModal() {
        this.setState({ modalVisible: false,  });
    }

    _renderShowContactModal() {
        return (
            <ContactInfo contact={this.state.showcontact}/>
        );
    }
    _renderModalContent() {
        if (this.state.showcontact) {
            return this._renderShowContactModal();
        }
        return null;
    }

    componentDidMount() {
        var that = this;
        NetworkService.getContacts(this.props.session)
        .then( (contacts) => {
            let list = [];
            for (let i=0; i<contacts.length; i++) {
                list.push(contacts[i]);
            }
            that.setState({contacts: list, loading: false,});
        }, (err)=> {
            console.log("error when fetching connections", err);
        });

        NetworkService.getProfiles(this.props.session).then((profiles) => {
            let list = [];
            for (let i=0; i<profiles.length; i++) {
                if (profiles[i].type === 7) {
                    list.push(profiles[i]);
                }
            }
            this.setState({ profiles: list, loading: false,});
        }).catch(()=> {
            console.log("error when fetching profiles");
        });

    }

    _viewContact(profile) {
        this.setState({
            modalVisible: true,
            showcontact: profile,
        });
    }

    _renderVirtualContacts() {

    }

    _renderContacts() {
        var list = [];
        for(let i=0; i<this.state.contacts.length;i++) {
            let contactprofile = this.state.contacts[i].profile;
            let displayname = Helper.concatObjectData(contactprofile, ['givenname', 'surname'], " ");
            let email = contactprofile.email;

            if (!displayname) {
                displayname = 'no name';
            }
            if (!email) {
                email = 'no email';
            }

            if (i > 0) {
                list.push(<Divider component="li" key={"_"+i} />);
            }
            list.push(
                <ListItem key={contactprofile.email}
                    button
                    onClick={() => { this._viewContact(contactprofile) }}
                >
                    <ListItemText secondary={email} primary={displayname} />
                    <ListItemIcon>
                    <img src={logo} style={{width: 20}} alt="Logo" />
                    </ListItemIcon>
                </ListItem>
            );
        }

        list.push(<Divider component="li" key={"uniqueSepator"} />)

        for(let i=0; i<this.state.profiles.length; i++) {
            let displayname = Helper.concatObjectData(this.state.profiles[i], ['givenname', 'surname'], " ");
            let email = this.state.profiles[i].email;

            if(!displayname) {
                displayname = "no name";
            }

            if(!email) {
                email = "no email"
            }

            if(i>0) {
                list.push(<Divider component="li" key={"_"+i} />);
            }

            list.push(
                <ListItem key={this.state.profiles[i].id}
                button
                onClick={() => { this._editProfile(this.state.profiles[i].id) }}>
                    <ListItemText secondary={email} primary={displayname} />
                    <ListItemIcon>
                        <img src={whitelogo} style={{width: 20}} alt="Logo" /> 
                    </ListItemIcon>
                </ListItem>
            )

        }

        if (list.length === 0) {
            list = (<p>No contacts</p>);
        }
        return list;
    }

    // Functions for handling VirtualProfiles

    _editProfile(profileid) {
        var state = this.state;
        state.profileid = profileid;
        this.props.history.push({
            pathname: '/editprofile',
            state: state
        })
    }

    _createVirtualProfile() {
        NetworkService.createEmptyProfile(this.props.session, 7)
        .then( (profile) => {
            this._editProfile(profile.id);
        })
        .catch((err) => {
            console.log("Error when creating profile", err);
        });
    }

    _updateuser = (person) => {
        let promise = new Promise((resolve,reject)=>{
            NetworkService.getProfile(person.id, this.props.session).then((profile) => {

                person.guid = profile.guid;

                NetworkService.updateProfile(
                    person,
                    this.props.session,
                    (val)=>{resolve(val)},
                    (err)=>{reject(err)},
                );
            });
        });
        return promise;
    }

    _createuser = (person) => {
        let promise = new Promise((resolve,reject) =>{
            NetworkService.createEmptyProfile(this.props.session, 7)
                .then((profile) => {
                    NetworkService.getProfile(profile.id, this.props.session).then((profile) => {

                        person.id = profile.id;
                        person.guid = profile.guid;

                        NetworkService.updateProfile(
                            person,
                            this.props.session,
                            (val)=>{
                                resolve(val);
                            },
                            (err)=>{reject(err)}
                        );
                    });
                }).catch((err) => {
                reject(err);
                console.log("Error when importing CSV", err);
            });
        })
        return promise;
    }

    _rerenderprofiles = async () => {
        let list = [];
        NetworkService.getProfiles(this.props.session).then((profiles) => {
            for (let i=0; i<profiles.length; i++) {
                if (profiles[i].type === 7) {
                    list.push(profiles[i]);
                }
            }
        }).then( () => {
            this.setState({ profiles: list, loading: false,});
            this._closeVirtualModal();
            this.forceUpdate();
        }).catch(()=> {
            console.log("error when fetching profiles");
        });
    }

    _importCSV = async () => {
        let profiles = this.state.csvData;
        let promises = [];
        for (let i = 0; i < profiles.length; i++) {
            let promise = new Promise((resolve,reject)=> {
                let person = profiles[i];
                let update = (Helper.empty(person.id)) ? false : true;

                // Update user
                if (update){
                    this._updateuser(person).then(val=>{
                        resolve(val);
                    }).catch(err=>{
                        reject(err);
                    })
                } else {
                    this._createuser(person).then(val => {
                        resolve(val);
                    }).catch(err => {
                        reject(err);
                    })
                }
            });
            promises.push(promise);
        }
        Promise.all(promises).then(val=>{
            this._rerenderprofiles();

        }).catch(err=>{
            console.log(err);
        })
    }

    _handleSaveProfile() {
        console.log("Profile saved");
    }

    _handleSaveProfileError() {
        console.log("Profile save error");
    }

    _generateCSVData() {
        let profiles = this.state.profiles;

        if (typeof profiles == 'undefined') {
            console.error("No profiles found")
            return null;
        }

        let header = [];
        for (let i = 0; i < Object.keys(profiles[0]).length; i++) {
            header.push(Object.keys(profiles[0])[i]);
        }

        let csvData = [ header ];

        for (let index = 0; index < profiles.length; index++) {
            let profile = [];
            for (let k = 0; k < Object.values(profiles[index]).length; k++) {
                profile.push(Object.values(profiles[index])[k]);
            }
            csvData.push(profile);
        }

        this.setState( {csvData: csvData} );
    }

    _closeVirtualModal() {
        this.setState({ virtualModalVisible: false, fileUploaded: false });
    }

    _openImportModal() {
        this.setState({ csvData: [], virtualModalVisible: true, errors: [], warnings: [] });
    }

    _handleImportError() {
        console.error("Error importing file");
    }

    _renderVirtualModalContent() {
        return (
            <div>
                { ! this.state.fileUploaded && (
                    <div className="import-instructions">
                        <span>1. Select the csv file you wish to import</span>
                        <span>2. Check preview for errors and warnings before pressing import
                        <span className={"list-indent"}>
                            <span> - <b>ERRORS</b>: These fields need to be altered before importing is possible</span>
                            <span> - <b>WARNINGS</b>: Warnings are NOT mandatory to fix before import. These only show where information is missing.</span>
                        </span>
                        </span>
                        <span>3. Import file</span>
                    </div>
                )}
                { this._renderPreviewTable() }
                { this._renderErrorsWarnings() }
                <div className={"csv-input-container"}>
                    <CSVReader
                        onError={ () => this._handleImportError()}
                        onFileLoaded={ data => this._validateCSVData( data )}
                    />
                </div>
            </div>
        );
    }

    _renderPreviewTable(){
        const data = this.state.csvData;

        if (data.length > 0) {
            let columns = Object.keys(data[0]);

            return(
                <Card className={"import-preview"}>
                    <CardHeader
                        avatar={
                            <ListIcon aria-label="Preview" />
                        }
                        title={"Import Preview (" + data.length + " users)" }
                        subheader="Make sure the preview are correct before importing."
                    />
                    <PaginationTable data={data} columns={columns} counting={true} />
                </Card>
            );
        }
    }

    _renderErrorsWarnings(){

        let errors = this.state.errors;
        let warnings = this.state.warnings;

        if ( errors.length > 0 || Object.entries(warnings).length > 0 )
        {
            let warnings_counter = 0;
            Object.keys(warnings).map( (key) => {
                warnings_counter += warnings[key].length;
            });

            return(
                <div className={"warning-error-wrapper"}>
                    { errors.length > 0 && (
                        <Card className={"errors-wrapper"}>
                            <CardHeader
                                avatar={
                                    <ErrorIcon style={ {color: "#cc3300" } } aria-label="errors" />
                                }
                                title={"Errors x " + errors.length }
                                subheader="Please check these fields before importing. This is mandatory."
                            />

                            <PaginationTable data={errors} columns={ ['Row', 'Field', 'Message'] } counting={false} />
                        </Card>
                    ) }

                    { Object.entries(warnings).length > 0 && (
                        <Card className={"warnings-wrapper"}>
                            <CardHeader
                                avatar={
                                    <WarningIcon style={ {color: "#ffcc00" } } aria-label="warnings" />
                                }
                                title={"Warnings x " + warnings_counter}
                                subheader="Please check these fields before importing. This is NOT mandatory."
                            />

                            { Object.keys(warnings).map((key) => {
                                    return (
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary
                                                className={"warnings-title"}
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <WarningIcon style={{color: "#ffcc00", fontSize: "16px"}}/>
                                                <span>{' ' + key + ' (' + warnings[key].length + ')'}</span>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <PaginationTable data={warnings[key]} columns={['Row', 'Warning']}
                                                                 counting={false}/>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    )
                                })
                            }
                        </Card>
                    ) }
                </div>
            )
        }
    }

    _validateCSVData( data ){

        this.setState({ fileUploaded: true })

        let profiles = [], errors = [], emails = [], phones = [];
        let warnings = {};
        let mapping = data[1];

        for (let i = 2; i < data.length; i++) {
            let profile = {};

            if ( data[i].length == mapping.length ) {
                Object.values(data[i]).map((value, index) => {
                    let key = mapping[index];
                    if (mapping[index].slice(-1) !== '*') {
                        if (!Helper.empty(key)) {
                            if (Helper.empty(value)) {
                                if (typeof warnings[key] == 'undefined') {
                                    warnings[key] = [];
                                }

                                warnings[key].push({row: i, warning: 'Missing value'});
                            }


                            if (key == "email") {
                                value.trim();
                                emails.push(value);
                                if (Helper.validateEmail(value) === false)
                                    errors.push({row: i, field: key, error: 'Wrong email format'});
                            } else if (key == "phone") {
                                value.trim();
                                phones.push(value);
                                if (Helper.validatePhone(value) === false)
                                    errors.push({row: i, field: key, error: 'Wrong phone format'});
                            }

                            profile[key] = value;
                        }
                    }
                });

                profiles.push(profile);
            }
        }

        // Check for duplicates
        let duplicates =[];
        if ( Helper.findDuplicates(phones).length > 0 )
            duplicates.push(Helper.findDuplicates(phones));
        else if ( Helper.findDuplicates(emails).length > 0 )
            duplicates.push(Helper.findDuplicates(emails));

        if (duplicates.length > 0) {
            for (let i = 0; i < duplicates.length; i++)
            {
                let field = (Helper.validateEmail(duplicates[i])) ? 'email' : 'phone';

                if (typeof warnings[field] == 'undefined')
                    warnings[field] = [];

                warnings[field].push({ row: 'unknown', warning: 'Duplicated value: ' + duplicates[i] });
            }
        }

        this.setState( {csvData: profiles, warnings: warnings, errors: errors} );
    }

    _renderButtons() {
        return (
            <div style={{margin: 20}}>
                <Grid container spacing={3}>
                    <Grid item xs={6} style={{paddingLeft: 0}}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={"action-btn"}
                            style={{
                                margin: 0
                            }}
                            onClick={() => this._createVirtualProfile()}
                        >
                            {'Create virtual profile'}
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <CSVLink data={this.state.csvData} className={"export-link"} filename={"virtual-profiles-export.csv"} enclosingCharacter={"\""}>
                            <Button
                                color="primary"
                                size="large"
                                className={"action-btn"}
                                onClick={() => this._generateCSVData()}
                            >
                                {'Export CSV'}
                            </Button>
                        </CSVLink>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            color="primary"
                            size="large"
                            className={"action-btn"}
                            onClick={() => this._openImportModal()}
                        >
                            {'Import CSV'}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }

    render() {
        var profiles = ( <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={3000} //3 secs
    
         />);
        if (!this.state.loading) {
            profiles = this._renderContacts();
        }
        var buttons = this._renderButtons();
        var importDisabled = (this.state.errors.length > 0 || this.state.csvData.length == 0);
        return (
            <Container component="main" align="center" maxWidth="md">
                 <Modal
                    isOpen={this.state.virtualModalVisible}
                    onRequestClose={this._closeVirtualModal}
                    style={{
                        content: {
                            top: '10%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            maxHeight: '80%',
                            maxWidth: '80%',
                            transform: 'translateX(-50%)'
                        }
                    }}
                    contentLabel="Import Modal">
                    <h2 className={"mt-0 align-center"}>Import CSV</h2>

                    <div>
                        {this._renderVirtualModalContent()}
                    </div>

                    <span className={(importDisabled && this.state.fileUploaded) ? 'import-label disabled' : 'import-label active'}>
                        { ( importDisabled && this.state.fileUploaded ) ? 'Please solve all your errors before importing.' : (( ! this.state.fileUploaded ) ? 'Please upload your CSV-file.' : 'Press the button to import your contacts.') }
                    </span>

                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => this._importCSV()}
                        disabled={ importDisabled }>
                        {"Import"}
                    </Button>
                    <CSVLink className={"download-template"} data={ 'ID (used to update user),First name,Surname,Nickname,Company,Position,Phone,Email,Street,Postcode,City,Country,Website\nid,givenname,surname,nickname,company,position,phone,email,street,postcode,city,country,webiste' }>
                        {"Download template"}
                    </CSVLink>
                </Modal>
                <Modal
                    isOpen={this.state.modalVisible}
                    onRequestClose={this._closeModal}
                    style={{
                        content: {
                            top: '10%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            maxHeight: '80%',
                            transform: 'translateX(-50%)'
                        }
                    }}
                    contentLabel="Example Modal"
                >
                    <div style={{"display": "flex", "flexFlow":"row-reverse", "justifyContent":"space-around"}}>
                        <div style={{position: 'absolute', right:0, top: 0}}>
                            <IconButton
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={this._closeModal}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>

                        {this._renderModalContent()}
                    </div>
                </Modal>
                <Paper style={{margin: 20}}>
                    {profiles}
                    {buttons}
                </Paper>
            </Container>
        );
    }
}
export default Contacts

import { combineReducers } from 'redux';
import session from './session';
import profile from './profile'
import share from './share';
import connection from './connection';
import contact from './contact';
import request from './request';
import token from './token';
import filter from './filter';
import filters from './filters';
import group from './group';
import user from './user';

const kApp = combineReducers({
    session,
    profile,
    share,
    connection,
    contact,
    request,
    token,
    filter,
    filters,
    group,
    user,
})

export default kApp
import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import image from './images/gradiant-large.jpg';
import CreateIcon from '@material-ui/icons/Share';
import ExitIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import InfoIcon from '@material-ui/icons/Info';
import startLogo from './images/k_logo_white_small.png';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
        ul: {
            margin: 0,
            padding: 0,
        },
        li: {
            listStyle: 'none',
        },
    },
    appBar: {

        backgroundColor: 'rgba(0,0,0,0)',

    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
        color: 'white',
        textDecoration: 'none',
        fontWeight: 'bold',
        verticalAlign: 'bottom',
        position: 'relative',

        "&::after": {
            content: "''",
            display: 'block',
            width: '0%',
            height: '2px',
            position: 'absolute',
            left: 0,
            bottom: '-10px',
            background: '#fff',
            zIndex: 999999,
            transition: 'all .2s ease-in'
        },
        "&:hover:after, &:active:after, &:focus:after": {
            width: 'calc(100% + 5px)'
        }
    },
    mobileLink: {
        color: 'black',
        textDecoration: 'none',

    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
        backgroundImage: `url(${image})`,
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
    media: {
        paddingTop: "56.25%"
    },
    content: {
        textAlign: "left",
        padding: theme.spacing(3)
    },
    heading: {
        fontWeight: "bold"
    },
    subheading: {
        lineHeight: 1.8
    },
    leftIcon: {
        marginRight: theme.spacing(1),
        verticalAlign: 'bottom',
    },
    styleIcon: {
        width: 20,
        height: 22,
        marginRight: 10,
        marginRight: theme.spacing(1),
        verticalAlign: 'bottom',
    },
    styleIconMobile: {
        width: 20,
        height: 22,
        marginRight: 10,
        marginRight: theme.spacing(1),
        verticalAlign: 'bottom',
        filter:"invert()"
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    grow: {
        flexGrow: 1,
    },
}));

const RenderNavbar = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const [open, setOpen] = React.useState(false);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleClose = () => {
        setOpen(false);
      };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleMobileMenuOpen = event => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const renderLogoutDialog = (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Logout"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to log out?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => { handleMenuClose(); props.history.push("/logout") }} color="primary" autoFocus>
                    Logout
                </Button>
            </DialogActions>
        </Dialog>
    );

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={() => { handleMenuClose(); props.history.push("/") }}>
                <IconButton color="inherit">
                    <img src={startLogo} alt="img" className={classes.styleIconMobile} />
                </IconButton>
                <p>Start</p>
            </MenuItem>
            <MenuItem onClick={() => { handleMenuClose(); props.history.push("/profile") }}>
                <IconButton color="inherit">
                    <PersonIcon />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
            <MenuItem onClick={() => { handleMenuClose(); props.history.push("/contacts") }}>
                <IconButton color="inherit">
                    <GroupIcon />
                </IconButton>
                <p>Contacts</p>
            </MenuItem>
            {/*<MenuItem onClick={() => { handleMenuClose(); props.history.push("/virtualprofiles") }}>
                <IconButton color="inherit">
                    <PersonIcon />
                </IconButton>
                <p>Virtual profiles</p>
    </MenuItem>*/}
            <MenuItem onClick={() => { handleMenuClose(); props.history.push("/grouplist") }}>
                <IconButton color="inherit">
                    <GroupIcon />
                </IconButton>
                <p>Groups</p>
            </MenuItem>
            <MenuItem onClick={() => { handleMenuClose(); props.history.push("/sharehistory") }}>
                <IconButton color="inherit">
                    <CreateIcon />
                </IconButton>
                <p>Share</p>
            </MenuItem>
            <MenuItem onClick={() => { handleMenuClose(); props.history.push("/about") }}>
                <IconButton color="inherit">
                    <InfoIcon />
                </IconButton>
                <p>About</p>
            </MenuItem>
            <MenuItem onClick={() => { handleClickOpen(); }}>
                <IconButton color="inherit">
                    <ExitIcon />
                </IconButton>
                <p>Logout</p>
            </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.grow}>
            <AppBar position="static" color="primary" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                        K for Contact
                </Typography>
                    <nav className={classes.sectionDesktop}>
                        <Link title={"Start"} variant="button" color="inherit" to="/" className={classes.link}>
                            <img src={startLogo} alt="img" className={classes.styleIcon} />Start
                    </Link>
                        <Link title={"Profile"} variant="button" color="inherit" to="/profile" className={classes.link}>
                            <PersonIcon className={classes.leftIcon} /> Profile
                    </Link>
                        <Link title={"Contacts"} variant="button" color="inherit" to="/contacts" className={classes.link}>
                            <GroupIcon className={classes.leftIcon} />Contacts
                    </Link>
                        {/*<Link title={"Virtual profiles"} variant="button" color="inherit" to="/virtualprofiles" className={classes.link}>
                            <PersonIcon className={classes.leftIcon} /> Virtual profiles
    </Link>*/}
                        <Link title={"Groups"} variant="button" color="inherit" to="/grouplist" className={classes.link}>
                            <GroupIcon className={classes.leftIcon} /> Groups
                    </Link>
                        <Link title={"Share"} variant="button" color="inherit" to="/sharehistory" className={classes.link}>
                            <CreateIcon className={classes.leftIcon} /> Share
                    </Link>
                    <Link title={"About"} variant="button" color="inherit" to="/about" className={classes.link}>
                            <InfoIcon className={classes.leftIcon} /> About
                    </Link>
                        <Link title={"Logout"} variant="button" color="inherit" onClick={handleClickOpen} to="/#"  className={classes.link}>
                            <ExitIcon className={classes.leftIcon} /> Logout
                    </Link>
                    </nav>
                    <nav className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                    </nav>

                </Toolbar>
            </AppBar>
            {renderLogoutDialog}
            {renderMobileMenu}
        </div>
    )
}

class Nav extends React.Component {
    render() {
        return (
            <RenderNavbar history={this.props.history} />
        )
    }
}
export default Nav;
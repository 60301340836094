import React, { Component } from 'react';
import Constants from '../../../services/Constants';
import Networkservice from '../../../services/Networkservice';
import Helper from '../../../services/Helper';
import { Container, } from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing(4, 4, 4, 4),
        direction: "column",
        alignItems: "center",
        justify: "center",
    },
    container: {
        alignItems: 'center',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        marginTop: theme.spacing(1),
    },
    iconbuttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    iconbutton: {
        marginTop: theme.spacing(.5),
    },
});

class ContactInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: this.props.contact,
        }
    }

    _renderPrimaryView(label, key, value) {

        if (!value) {
            value = this.state.profile[key] + "";
        }
        return (
            <span key={key+value}>
                <label><strong>{label}</strong></label><br/><span style={{ margin: 8 }} >{value}</span>
            </span>
        );
    }

    _renderSecondaryViewList(label, type) {
        let rows = [];
        let item = 0;
        if (this.state.profile && this.state.profile.profiledata && this.state.profile.profiledata.length > 0) {
            rows = this.state.profile.profiledata.map((r, i) => {
                if (r.type === type) {
                    return this._renderSecondaryViewItem(label, r.type, item++);
                }
                else {
                    return null;
                }

            })
            .filter((r)=>{return r !== null});
        }
        return rows;
    }

    _getProfileDataByType(type) {
        let profiledata = this.state.profile.profiledata;
        let result = [];
        if (profiledata && profiledata.length > 0) {
            for (let i = 0; i < profiledata.length; i++) {
                if (profiledata[i]['type'] === type) {
                    profiledata['row'] = i;
                    result.push(profiledata[i]);
                }
            }
        }
        return result;
    }

    _getProfileDataItemByType(type, item) {
        let result = this._getProfileDataByType(type);
        if (result.length > item) {
            return result[item];
        }
        else {
            return {
                id: null,
                row: null,
                type: type,
                value: '',
                label: ''
            };
        }
    }

    _setLabelByType(type) {
        if (type === 'status_note') {
            return 'Note';
        }
        else if (type === 'out_of_office') {
            return 'Out of office';
        }
        else if (type === 'phone') {
            return 'Phone';
        }
        else if (type === 'email') {
            return 'Email';
        }
        else if (type === 'website') {
            return 'Website';
        }
        else {
            for (let i = 0; i < Constants.SocialMedia.length; i++) {
                if (Constants.SocialMedia[i].key === type) {
                    return Constants.SocialMedia[i].label;
                }
            }
            return type;
        }
    }
    _renderSecondaryViewItem(label, type, item = 0, value) {
        let profiledata = {};
        if (!value) {
            profiledata = this._getProfileDataItemByType(type, item);
            value = profiledata.value + "";
        }
        if ((type === 'email') || (type === 'phone')) {
            type = type + '_list';
        }
        let margin = 8;
        if (type === 'out_of_office' || type === 'status_note') {
            margin = 0;
        }
        if (value) {
            return (
                <div key={label+value} style={{ marginLeft: margin }}>{value}</div>
            );
        }
        else {
            return null;
        }
    }
    _renderImage() {
            if (!this.state.profile.image_guid)
                return null;
            let url = Networkservice.getImageUrl(this.state.profile.image_guid);
            return (
            <div style={{textAlign: 'center', width: '100%' }}>
                <div style={{width:200, height: 200, position: 'relative', overflow: 'hidden', borderRadius: '50%', marginLeft: 'auto', marginRight: 'auto', border: '3px solid RoyalBlue'}}>
                    <img src={url} style={{maxHeight: 200, maxWidth: 200, display: 'inline', margin:0, height: '100%', width: 'auto' }} alt="Profile"/>
                </div>
            </div>
            );
    }
    _renderName() {
        let displayname = Helper.concatObjectData(this.state.profile, ['givenname', 'surname'], " ");
        if (!displayname && !this.state.profile.nickname) {
            displayname = 'no name';
        }
            return (
                <div style={styles.contactLineName}>
                    <div style={{textAlign: 'center'}}>
                        <p style={styles.contactLineNameText}><strong>{displayname}</strong></p>
                        <p style={styles.contactLineNicknameText}>{this.state.profile.nickname}</p>
                    </div>
                </div>
            );
    }

    _renderStatus() {
        let rows = [];
        let statusrow = this._renderSecondaryViewItem('Status note', 'status_note');
        let ooorow = this._renderSecondaryViewItem('Out of office', 'out_of_office');
        if (statusrow != null) {
            rows.push(statusrow);
        }
        if (ooorow != null) {
            rows.push(ooorow);
        }
        if (!rows.length) {
            return;
        }
        return (
            <div style={{marginTop: 15}}>
                {rows}
            </div>
        );
    }

    _renderCompany() {

        let company = Helper.concatObjectData(this.state.profile, ['company', 'position'], ", ");
        if (!company) {
            return null;
        }
        return (
            <div style={{marginTop: 15}}>
                {this._renderPrimaryView('Company', 'company', company)}
            </div>

        );
    }
    _renderPhone() {
        let stuff = this._renderSecondaryViewList('Phone', 'phone', 'phone-pad');
        let stuff2 = this._renderPrimaryView('Phone', 'phone');
        if (!stuff.length && !stuff2)
            return null;
        return (
            <div style={{marginTop: 15}}>
                {stuff2}
                {stuff}
            </div>
        );
    }
    _renderEmail() {
        return (
            <div style={{marginTop: 15}}>
                <div>
                    {this._renderPrimaryView('Email', 'email')}
                </div>
                <div>
                    {this._renderSecondaryViewList('Email', 'email', 'email-address')}
                </div>
            </div>
        );
    }

    _renderAddress() {
        if (!this.state.profile.street) {
            return;
        }
        else {
            return (
                <div style={{marginTop: 15, whiteSpace: 'pre-line'}}>
                    {this._renderPrimaryView('Address', 'street')}
                    {this._renderSecondaryViewItem('','',0, this.state.profile.postcode + " " + this.state.profile.city)}
                    {this._renderSecondaryViewItem('','',0, this.state.profile.country )}
                </div>
            );
        }
    }

    _renderSocialMedia() {
        let rows = [];
        let row = null;
        if (this.state.profile.website) {
            row = this._renderPrimaryView('Website', 'website');
            rows.push(row);
        }
        for (let i = 0; i < Constants.SocialMedia.length; i++) {
            let media = Constants.SocialMedia[i];
            row = this._renderSecondaryViewItem(media.label, media.key);
            if (row) {
                rows.push( (<div key={'social'+i} style={{marginTop:15}}><strong>{media.label}</strong></div>) );
                rows.push(row);
            }
        }
        if (rows.length) {
            return (
                <div style={{marginTop: 15}}>
                    {rows}
                </div>
            );
        }
        else {
            return null;
        }
    }


    render() {
        return (
                <Container maxWidth="xl" component="main" style={{width: 450, maxWidth: '100%'}}>
                    {this._renderImage()}
                    {this._renderName()}
                    {this._renderStatus()}
                    {this._renderPhone()}
                    {this._renderCompany()}
                    {this._renderEmail()}
                    {this._renderAddress()}
                    {this._renderSocialMedia()}
                </Container>
        );
    }
}
export default ContactInfo

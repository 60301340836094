const contact = (state = [], action) => {
    switch (action.type) {
        case 'SET_CONTACT':
            return ({
                id: action.contact.id,
                guid: action.contact.guid,
                recordID: action.contact.recordID,
                rawContactID: action.contact.rawContactID,
                displayname: action.contact.displayname,
                givenname: action.contact.givenname,
                surname: action.contact.surname,
                nickname: action.contact.nickname,
                company: action.contact.company,
                position: action.contact.position,
                phone: action.contact.phone,
                email: action.contact.email,
                street: action.contact.street,
                postcode: action.contact.postcode,
                city: action.contact.city,
                country: action.contact.country,
                website: action.contact.website,
                image: action.contact.image,
                profiledata: action.contact.profiledata,
                active: action.contact.active,
                recipient_active: action.contact.recipient_active,
                modified: action.contact.modified,
            })
        default:
            return state
    }
}

export default contact
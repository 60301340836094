
/* 
	TODO: to avoid confusion this should have been 
	called "filterlist" instead of filter but that required
	alot of recoding
*/

const filters = (state = [], action) => {
    switch (action.type) {
        case 'SET_FILTERS':
            return ({
                list: action.filters,
            })
        default:
            return state
    }
}

export default filters
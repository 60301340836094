import React, { Component } from 'react';
import { connect } from 'react-redux'
import Moment from 'moment';
import NetworkService from '../../../services/Networkservice';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import QRCode from 'qrcode.react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Checkbox, MenuItem, Select, Paper, withStyles, Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const mapStateToProps = function (state) {
    return {
        session: state.session,
        share: state.share,
        profile: state.profile,
        filters: state.filters.list,
    }
};
const StyledTableRow = withStyles(theme => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.background.default
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const styles = theme => ({

    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    listitem: {
        fontWeight: "bold"
    },
    table: {
        marginLeft: theme.spacing(8),
        marginRight: theme.spacing(8),
    }
});

class ShareView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            share: this.props.share,
            connections: [],
            filterlist: this.props.filters
        }
        this._handleConnectionError = this._handleConnectionError.bind(this);
        this._handleConnectionList = this._handleConnectionList.bind(this);
        this._renderConnections = this._renderConnections.bind(this);
        this._handleFirstConnectionList = this._handleFirstConnectionList.bind(this);
        this._setFilter = this._setFilter.bind(this);
        this._saveShare = this._saveShare.bind(this);
        this._handleSaveShare = this._handleSaveShare.bind(this);
        this._handleSaveShareError = this._handleSaveShareError.bind(this);
        this._renderFilterRows = this._renderFilterRows.bind(this);
        this._renderFilter = this._renderFilter.bind(this);
        this._toggleShareSetting = this._toggleShareSetting.bind(this);
        this._renderSettings = this._renderSettings.bind(this);
        this._renderConnections = this._renderConnections.bind(this);
        this._handleDelete = this._handleDelete.bind(this);

    }
    componentDidMount() {
        this._mounted = true;
        Moment.locale("en");
        NetworkService.getShareConnections(this.state.share.guid, this.props.session, this._handleFirstConnectionList, function (err) {
        });
    }

    componentWillUnmount() {
        this._mounted = false;
    }
    _handleFirstConnectionList(connections) {
        let that = this;
        if (this._mounted) {
            // set initial amount of connections
            this.setState({
                connections: connections
            }, function () {
                // now check for new connections after four seconds
                setTimeout(function () {
                    if (that._mounted) {
                        NetworkService.getShareConnections(
                            that.state.share.guid,
                            that.props.session,
                            that._handleConnectionList,
                            that._handleConnectionError
                        );
                    }
                }, 4000); // first time we wait 4 seconds
            });
        }
    }

    _handleConnectionList(connections) {
        let that = this;
        if (this._mounted) {
            // only perform this if the component is mounted           
            if (that.state.connections.length === connections.length) {
                // if there are no connections then check again in two seconds
                setTimeout(function () {
                    if (that._mounted) {
                        NetworkService.getShareConnections(
                            that.state.share.guid,
                            that.props.session,
                            that._handleConnectionList,
                            that._handleConnectionError
                        );
                    }
                }, 2000);
            }
        }
    }
    _handleConnectionError(error) {
        if (this._mounted) {
            // we silently ignore this error as is can occur when we leave the app and come back
            //Toast.show({ text: error, position: 'top', type: 'warning' });
        }
    }

    _renderConnections() {
        const { classes } = this.props;
        let rows = null;
        if (this.state.connections.length > 0) {
            rows = <TableBody>
                {this.state.connections.map((r, i) => {
                    // connection list no longer have profile included!
                    var displayName = 'Unknown';
                    if (r.profile !== null) {
                        displayName = r.profile.nickname;
                    }
                    return (
                        <StyledTableRow key={i}>
                            <StyledTableCell component="th" scope="rows">
                                {displayName}
                            </StyledTableCell>
                            <StyledTableCell align="center">{r.guid}</StyledTableCell>
                            <StyledTableCell align="right">{Moment(r.created).format('DD/MM/YYYY')}</StyledTableCell>
                        </StyledTableRow>
                    );
                })}
            </TableBody>
        }
        if (rows) {
            return (
                <React.Fragment>
                    <Typography variant="h6" gutterBottom>Connections established</Typography>
                    <Table className={classes.table}>
                        {rows}
                    </Table>
                </React.Fragment>
            );
        }
        else {
            return null;
        }
    }
    _toggleShareSetting(setting) {
        let that = this;
        let share = this.state.share;
        share[setting] = (share[setting] === 1) ? 0 : 1;
        this.setState({
            share: share,
        }, function () {
            that._saveShare();
        });
    }
    _saveShare() {
        NetworkService.updateShare(
            this.state.share,
            this.props.session,
            this._handleSaveShare,
            this._handleSaveShareError,
        );
    }
    _deleteShare() {
        NetworkService.deleteShare(this.state.share.guid, this.props.session, this._handleDelete, function (err) {
            // Toast.show({ text: err, position: 'top', type: 'warning' });
        });
    }

    _handleDelete() {
        this.props.history.push("/ShareHistory");
    }

    _renderSettings() {
        if (this.state.share.sharedata && this.state.share.sharedata.profile) {
            return (
                <React.Fragment>
                    <ListItem style={{ justifyContent: 'center' }}>
                        <Button variant="contained" size="large" color="primary" onClick={() => this._deleteShare()}>
                            Revoke Invite
                        </Button>
                    </ListItem>
                    <Divider variant="middle" />
                </React.Fragment>
            );
        }
        else if (this.state.share.profile.type === 4) {
            return (
                <React.Fragment>
                    <ListItem>
                        <ListItemIcon>
                            <Checkbox
                                checked={(this.state.share.single === 1)}
                                onChange={(e) => this._toggleShareSetting('single')}
                            />
                        </ListItemIcon>
                        <ListItemText primary='Single' secondary="This share can only be scanned once, then it is consumed." />
                    </ListItem>
                    <Divider variant="middle" />
                    <ListItem style={{ justifyContent: 'center' }}>
                        <Button variant="contained" size="large" color="primary" onClick={() => this._deleteShare()}>
                            Delete
                        </Button>
                    </ListItem>
                    <Divider variant="middle" />
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <ListItem>
                        <ListItemIcon>
                            <Checkbox checked={(this.state.share.single === 1)} onChange={(e) => this._toggleShareSetting('single')} />
                        </ListItemIcon>
                        <ListItemText primary='Single' secondary="This share can only be scanned once, then it is consumed." />
                    </ListItem>
                    <Divider variant="middle" />
                    <ListItem>
                        <ListItemIcon>
                            <Checkbox checked={(this.state.share.auto_accept === 1)} onChange={(e) => this._toggleShareSetting('auto_accept')} />
                        </ListItemIcon>
                        <ListItemText primary='Auto accept' secondary="If someone scans this share then they are directly granted access to your information." />
                    </ListItem>
                    <Divider variant="middle" />
                    <ListItem style={{ justifyContent: 'center' }}>
                        <Button variant="contained" size="large" color="primary" onClick={() => this._deleteShare()}>
                            Delete
                        </Button>
                    </ListItem>
                </React.Fragment>
            );
        }
    }

    _renderFilterRows() {
        let rows = [];
        if (this.state.filterlist && this.state.filterlist.length > 0) {
            rows = this.state.filterlist.map((r, i) => {
                return (
                    <MenuItem style={{ flexDirection: 'column', alignItems: 'left' }} key={'filter_' + (r.id)} value={r.id} >{r.name}</MenuItem>
                );
            });
        }
        rows.unshift(<MenuItem style={{ flexDirection: 'column', alignItems: 'left' }} key={'filter_null'} value={"Full profile"} >Full profile</MenuItem>);
        return rows;
    }
    _setFilter(filterid) {
        let that = this;
        let share = this.state.share;

        if (filterid === "Full profile") {
            filterid = null;
        }

        share.filterid = filterid;
        this.setState({
            share: share,
        }, function () {
            that._saveShare();
        });
    }

    _handleSaveShare(share) {
        // silently update without notification
    }

    _handleSaveShareError(error) {
        console.log("error", error);
        //   Helper.addLog('Image._handleError', Helper.LOG_ERROR, error);
        //  Toast.show({ text: lang.t("SHARE_SAVE_FAILED"), position: 'top', type: 'danger' });
    }

    _renderFilter() {
        const { classes } = this.props;
        if (this.state.share.profile.type === 4) {
            // groups do not support filters for now
            return (
                <React.Fragment>
                    <ListItem className={classes.listitem}>{"Profile"}</ListItem>
                    <ListItem className={classes.list}>{"The group only shares it's own profile. You can change which profile you show to the group within your own connection to the group."}</ListItem>
                    <Divider variant="middle" />
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <ListItem>Profile</ListItem>
                    <Select
                        style={{ width: '100%', flexDirection: 'column', alignItems: 'left' }}
                        name="name"
                        value={this.state.share.filterid === null ? "Full profile" : this.state.share.filterid}
                        onChange={event => this._setFilter(event.target.value)}
                    >
                        {this._renderFilterRows()}
                    </Select>
                    <ListItem>Select which profile you want to share. You can add or edit the selection in your profile settings.</ListItem>
                </React.Fragment>
            );
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Container component="main" align="center" maxWidth="md">
                <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom>{this.state.share.sharename}</Typography>
                    <List>
                        <ListItem className={classes.list}>
                            <ListItemIcon>
                                <QRCode
                                    value={'https://link.kforcontact.com/share/' + this.state.share.guid.toString()}
                                    size={200}
                                    bgColor='white'
                                    fgColor='black' />
                            </ListItemIcon>
                        </ListItem>
                        <ListItem className={classes.list}>{"Ask the recipient to scan the code above or use the buttons below to send it to them."}</ListItem>
                        <Divider variant="middle" />
                        {this._renderFilter()}
                        {this._renderSettings()}
                    </List>
                    {this._renderConnections()}
                </Paper>
            </Container>
        );
    }
}
export default connect(mapStateToProps)(withStyles(styles)(ShareView))

import React from 'react';
import { connect } from 'react-redux';
import NetworkService from '../../services/Networkservice';
import Helper from '../../services/Helper';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import logo from '../../images/k_logo_white_med.png';
import { withStyles, Grid, TextField, Button, FormControlLabel, Checkbox, MuiThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function MadeWithMaterialUI() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'K for Contact - created by '}
        <Link color="inherit" href="https://newseed.se/">
          Newseed
        </Link>
        {'.'}
      </Typography>
    );
}

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
      overrides: {  
      MuiFormLabel: {
        root: {
          "&$focused": {
            color: 'white',
          }
        },
        focused: {}
      },
      MuiCheckbox: {
        colorPrimary: {
          "&$checked": {
            color: 'white',
          }
        },
        checked: {}
      },  
      MuiButton: {        
          text: {
              // Some CSS
              //background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
              borderRadius: 3,
              border: 0,
              color: 'white',
              height: 48,
              padding: '0 30px',
              boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
          },
      },
    },
});

const styles = theme => ({
    '@global': {
      body: {
        //backgroundColor: theme.palette.primary.light,        
      },
    },
    paper: {
      marginTop: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
    },
    avatar: {
      margin: theme.spacing(5),
      width: theme.spacing(9),
      height: theme.spacing(9),
      backgroundColor: 'transparent',
      color: 'black',
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
      color: 'white',
      
    },
    submit: {
      margin: theme.spacing(3, 0, 2),        
    },
    whiteText: {
      color: 'white',
      //backgroundColor: 'white',
      
    },
    emailForm: {
      //border: '1px solid white',
      //borderColor: '1px solid white',
      //borderRadius: '4px',
      borderWidth: '1px',
      borderColor: 'white !important',
      //borderColor: 'white',
    },
    notchedOutline: {
      borderWidth: '1px',
      borderColor: 'white !important',
      
    },
    cssFocused: {
      //color: 'white',
    },

    cssOutlinedInput: {
      '&$cssFocused $notchedOutline': {
        //borderColor: `${theme.palette.primary.main} !important`,
        borderColor: 'white !important',
      }
    },
    formLabelRoot: {
      '&$formLabelFocused': {color: 'white'},
    },

  });

  const mapStateToProps = function (state) {    
    return {
        session: state.session,
        token: state.token,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSession: (session) => dispatch({
            type: 'SET_SESSION',
            session: session,
        }),
        setProfile: (profile) => dispatch({
            type: 'SET_PROFILE',
            profile: profile,
        }),       
        setFilters: (filters) => dispatch({
            type: 'SET_FILTERS',
            filters: filters,
        }),
        setUser: (user) => dispatch({
            type: 'SET_USER',
            user: user,
        }),
        setToken: (token) => dispatch({
            type: 'SET_TOKEN',
            token: token,
        }),
    };
};

const LOGIN_BY_EMAIL = 1;
const LOGIN_BY_TOKEN = 2;
const LOGIN_BY_PASSWORD = 3;

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {
                username: "",
                password: "",
                email: "",
                secret: "",
            },            
            loading: false,
            login_mode: LOGIN_BY_EMAIL,
            remember: false,
           
        }
        this._login = this._login.bind(this);
        this._handleLogin = this._handleLogin.bind(this);
        this._handleLoginSuccess = this._handleLoginSuccess.bind(this);
        this._handleLoginFail = this._handleLoginFail.bind(this);
    }

   

    componentDidMount() {
        // Logout
        localStorage.removeItem('Session');
        this.props.setSession('');
    }

    _handleInputChange(field, e) {
        var val =  e.target.value;
        let user = this.state.user;
        user[field] = val;
          this.setState({ user: user }, function () {
        });
    }

    _setLoginMode(mode) {
        // TODO: abort network calls still active?
        this.setState({
            login_mode: mode,
            loading: false,
        });
    }


    _login() {
        let that = this;       
        if (this.state.login_mode === LOGIN_BY_EMAIL){
            if (Helper.looksLikeAnEmail(that.state.user.email)){
                setTimeout(function () {
                    NetworkService.login(that.state.user, function (token) {
                        // TODO: verify that it looks like a token
                        if (token.guid) {
                            that.props.setToken(token);
                            that.setState({
                                login_mode: LOGIN_BY_TOKEN,
                                loading: false,
                            });
                        }
                        else {
                            console.log('Login._login:none');
                            that._handleLoginFailExplained('LOGIN_COMMON_EMAIL_NONE', LOGIN_BY_EMAIL);
                        }
                    }, function (err) {
                        console.log(err);
                        console.log('Login._login:fail');
                        that._handleLoginFailExplained('LOGIN_COMMON_EMAIL_FAILED', LOGIN_BY_EMAIL);
                    });
                }, 200);
            }
            else {
                //Helper.addLog('Login._login:invalid',Helper.LOG_INFO,{});
                //that._handleLoginFailExplained('LOGIN_COMMON_EMAIL_INVALID',LOGIN_BY_EMAIL);

                // "hidden" login by username feature, switch mode. God or bad?
                let user = that.state.user;
                user.username = that.state.user.email;
                that.setState({
                    login_mode: LOGIN_BY_PASSWORD,
                    loading: false,
                    user: user,
                });
            }
        }
        else if (this.state.login_mode === LOGIN_BY_TOKEN){
            NetworkService.loginByToken(
                that.props.token.guid,
                that.state.user,
                that._handleLogin,
                function (err) {
                    console.log('Login._login:token');
                    that._handleLoginFailExplained('LOGIN_COMMON_TOKEN_FAILED', LOGIN_BY_EMAIL);
                }
            );
        }
        else{
            NetworkService.login(that.state.user, that._handleLogin, function (err) {
                console.log('Login._login:password');
                that._handleLoginFailExplained('LOGIN_COMMON_USER_FAILED', LOGIN_BY_PASSWORD);
            });

        }
    }

    _handleLogin(session) {    
        var that = this;       
        if (session.guid){            
            NetworkService.getProfile(session.profileid, session).then((profile) => {              
                NetworkService.getFilters(session, function (filters) {                    
                    that._handleLoginSuccess(session, profile, filters);             
                }, this._handleLoginFail );
            }).catch(()=> this._handleLoginFail());
        }
        else{
            this._handleLoginFail();
        }

    }
    _handleLoginSuccess(session, profile, filters){   
      console.log("session, profile, filters-->", session, profile, filters);   
        var that = this;       
        that.setState({ loading: false }, function(){
            NetworkService.getUser(session.userid, session, function (user) {
                that.props.setUser(user);     
                that.props.setProfile(profile);
                that.props.setFilters(filters);
                that.props.setSession(session);               
                // Only save in localStorage if remember me is checked
                if (that.state.remember) {
                    localStorage.setItem("Session", that.props.session.guid);
                }
                that.props.history.push("/");
            }, function (err) {
              console.log("errroor", err);
                
            });
        });
       
    }

    _handleLoginFail(){
        console.log("_handleLoginFail");
        // call generic fail with set mode
        this._handleLoginFailExplained('LOGIN_COMMON_USER_FAILED', this.state.mode );
    }
    
    _handleLoginFailExplained(text,mode){     
        this.setState({ 
            loading: false,
            mode: mode
        });
    }

    _renderEmailLogin(classes) {
        return (
            <>
                <TextField
                    InputProps={{
                        className: classes.whiteText,
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.whiteText,
                            focused: classes.whiteText,

                        },
                        FormLabelClasses: {
                            root: classes.formLabelRoot,
                            focused: classes.whiteText,

                        },
                    }}
                    color="primary"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={this.state.user.email}
                    onChange={e => this._handleInputChange('email', e)}
                />
                <FormControlLabel
                    control={<Checkbox className={classes.whiteText} value="remember" color="primary" checked={this.state.remember} onChange={e => this.setState({remember: e.target.checked})} />}
                    label="Remember me"
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={(e) => {e.stopPropagation(); this._login()}}
                >
                    Sign In
            </Button>
                {/*<Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" className={classes.whiteText}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2" className={classes.whiteText}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>*/}
            </>
        );
    }

    _renderTokenLogin(classes) {
        return (
            <>
                <label>{this.state.user.email}</label>
                <TextField
                    InputProps={{
                        className: classes.whiteText,
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.whiteText,
                            focused: classes.whiteText,

                        },
                        FormLabelClasses: {
                            root: classes.formLabelRoot,
                            focused: classes.whiteText,

                        },
                    }}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    autoFocus
                    id="token"
                    label="token"
                    name="token"
                    autoComplete="token"
                    autoFocus
                    value={this.state.user.secret}
                    onChange={e => this._handleInputChange('secret', e)}
                />
                <FormControlLabel
                    control={<Checkbox value="remember" color="primary" checked={this.state.remember} onChange={e => this.setState({remember: e.target.checked})} />}
                    label="Remember me"
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={(e) => {e.stopPropagation(); this._login()}}
                >
                    Sign In
            </Button>
                {/*<Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>*/}
            </>
        );
    }

    _renderElseLogin(classes) {
        return (
            <>
                <label>{this.state.user.username}</label>
                <TextField
                    InputProps={{
                        className: classes.whiteText,
                        classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.whiteText,
                            focused: classes.whiteText,

                        },
                        FormLabelClasses: {
                            root: classes.formLabelRoot,
                            focused: classes.whiteText,

                        },
                    }}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    autoFocus
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={this.state.user.password}
                    onChange={e => this._handleInputChange('password', e)}
                />
                <FormControlLabel
                    control={<Checkbox value="remember" color="primary" checked={this.state.remember} onChange={e => this.setState({remember: e.target.checked})} />}
                    label="Remember me"
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={(e) => {e.stopPropagation(); this._login()}}
                >
                    Sign In
                    </Button>
                {/*<Grid container>
                      <Grid item xs>
                        <Link href="#" variant="body2" >
                          Forgot password?
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link href="#" variant="body2">
                          {"Don't have an account? Sign Up"}
                        </Link>
                      </Grid>
                    </Grid>*/}
            </>
        );
    }

    render() {
        const { classes } = this.props;
        let render = null;
        if (this.state.login_mode === LOGIN_BY_EMAIL) {
            render = this._renderEmailLogin(classes);
        } else if (this.state.login_mode === LOGIN_BY_TOKEN) {
            render = this._renderTokenLogin(classes);
        } else {
            render = this._renderElseLogin(classes);
        }

        return (
            <MuiThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">

                    <CssBaseline />

                    <div className={classes.paper}>
                        <Avatar className={classes.avatar} src={logo}>
                            <img src={logo} alt="Logo" />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <form className={classes.form} noValidate onSubmit={e => {e.preventDefault(); this._login();}}>
                            {render}
                        </form>
                    </div>

                    <Box mt={5}>
                        <ThemeProvider theme={theme}>
                            <MadeWithMaterialUI />
                        </ThemeProvider>
                    </Box>
                </Container>
            </MuiThemeProvider>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login))

import React, { Component } from 'react';
import Helper from '../../../services/Helper';
import ContactInfo from '../../common/contactinfo';
import {Paper, Divider, ListItem, ListItemText, Container, Button, IconButton, Grid} from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Cancel";
import Modal from 'react-modal';
import NetworkService from '../../../services/Networkservice';
import { saveAs } from 'file-saver';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

Modal.setAppElement('#root');

class GroupMembers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            groupguid: this.props.location.state.groupguid,
            groupid: this.props.location.state.groupid,
            selectedvirtualprofiles: [],
            members: [],
            modalVisible: false,
            insertVirtualProfile: false,
            loading: true,
        }
        this._closeModal = this._closeModal.bind(this);
        this._insertVirtualProfile = this._insertVirtualProfile.bind(this);
        this._insertVirtualProfiles = this._insertVirtualProfiles.bind(this);
        this._renderModalContent = this._renderModalContent.bind(this);
        this._renderMembers = this._renderMembers.bind(this);
        this._leaveGroup = this._leaveGroup.bind(this);
        this._selectAllVirtualProfiles = this._selectAllVirtualProfiles.bind(this);
        this._selectVirtualProfile = this._selectVirtualProfile.bind(this);
    }

    componentDidMount() {
        let guid = this.state.groupguid;
        let promises = [];
        promises.push(NetworkService.getGroup(guid, this.props.session));
        promises.push(NetworkService.getProfiles(this.props.session));

        Promise.all(promises)
        .then( ([group, profiles]) => {
            let list = [];
            for (let i=0; i<profiles.length; i++) {
                if (profiles[i].type === 7) {
                    list.push(profiles[i]);
                }
            }
            this.setState({
                members: group.connectionlist,
                groupname: group.profile.nickname,
                virtualprofiles: list,
                loading: false,
            });
        }).catch( () => {
            console.log("error when fetching members");
        });
    }

    _closeModal() {
        this.setState({ modalVisible: false, insertVirtualProfile: false });
    }

    _showMember(profile) {
        this.setState({
            modalVisible: true,
            showmember: profile,
        });
    }

    _getConnectionReport() {
      NetworkService.getConnectionReport(this.props.session, this.state.groupguid).then(value=>{
        saveAs( value, 'report.xlsx');
      }).catch(error=>{
        console.log(error)
      });
    }

    _insertVirtualProfile() {
        this.setState({
            modalVisible: true,
            insertVirtualProfile: true,
        });
    }

    _insertVirtualProfiles() {
        let promises = [];
        for (let i=0; i<this.state.selectedvirtualprofiles.length; i++) {
            promises.push(NetworkService.createConnection(this.props.session, this.state.selectedvirtualprofiles[i], this.state.groupid));
        }
        console.log(Promise);
        Promise.all(promises)
        .then( () => {
            alert("Connections made");
            this.setState({selectedvirtualprofiles: []});
            NetworkService.getGroup(this.state.groupguid, this.props.session)
            .then( (group) => {
                this.setState({ members: group.connectionlist,});
            });
        }, (err) => {
            console.log(err);
        });
    }

    _leaveGroup() {
        NetworkService.deleteConnection(this.props.session, this.state.groupguid)
        .then( () => {
            alert("Left group");
            this.props.history.goBack();
        })
        .catch((err)=>{
            console.log(err);
        });
    }

    _renderMembers() {
        var list = [];
        for (let i = 0; i < this.state.members.length; i++) {
            if (i > 0) {
                list.push(<Divider component="li" key={"_"+i} />);
            }
            let displayname = Helper.concatObjectData(this.state.members[i].profile, ['givenname', 'surname'], " ");
            list.push(
                <ListItem key={this.state.members[i].guid} button={true}
                    onClick={ () => { this._showMember(this.state.members[i].profile); } }
                >
                    <ListItemText primary={displayname} />
                </ListItem>
            );
        }
        if (list.length === 0) {
            list = (<p>No members</p>);
        }
        return list;
    }

    _selectVirtualProfile(event,profileguid) {
        let selectedvirtualprofiles = this.state.selectedvirtualprofiles;
        let index = selectedvirtualprofiles.indexOf(profileguid);
        if (event.target.checked) {
            if (index !== -1) {
                return;
            }
            selectedvirtualprofiles.push(profileguid);
        } else {
            if (index === -1) {
                return;
            }
            selectedvirtualprofiles.splice(index,1);
        }
        this.setState({selectedvirtualprofiles: selectedvirtualprofiles});
    }

    _selectAllVirtualProfiles(event) {
        let selectedvirtualprofiles = [];
        if (event.target.checked) {
            for (let i=0; i<this.state.virtualprofiles.length; i++) {
                selectedvirtualprofiles.push(this.state.virtualprofiles[i].guid);
            }
        }
        this.setState({selectedvirtualprofiles: selectedvirtualprofiles});
    }

    _renderInsertVirtualProfileModal() {
        //var options = [];
        var list = [];
        list.push(
            <ListItem key={"_0"}>
                <input
                    type="checkbox"
                    style={{"marginLeft":"-1em", "marginRight":"1em"}}
                    onChange={this._selectAllVirtualProfiles}
                />
            </ListItem>
        );
        for (let i=0; i<this.state.virtualprofiles.length; i++) {
            //options.push(
            //    <option value={this.state.virtualprofiles[i].id}>
            //        {this.state.virtualprofiles[i].email}
            //    </option>
            //);
            list.push(<Divider component="li" key={"_"+this.state.virtualprofiles[i].id} />);
            let displayname = Helper.concatObjectData(this.state.virtualprofiles[i], ['givenname', 'surname'], " ");
            let email = this.state.virtualprofiles[i].email;
            list.push(
                <ListItem key={this.state.virtualprofiles[i].id}>
                    <input
                        type="checkbox"
                        style={{"marginLeft":"-1em", "marginRight":"1em"}}
                        onChange={ (event) => { this._selectVirtualProfile(event,this.state.virtualprofiles[i].guid); } }
                        checked={this.state.selectedvirtualprofiles.indexOf(this.state.virtualprofiles[i].guid) !== -1}
                    />
                    <ListItemText secondary={email} primary={displayname} />
                </ListItem>
            );
        }

        if (list.length < 3) {
            list = (<p>You do not have any groups</p>);
        } else {
            list.push(<Divider component="li" key={"_0_"} />);
            list.push(
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={()=>{this._insertVirtualProfiles();}}
                >
                    {'Add contact'}
                </Button>
            );
        }
        return (
            <div>
                {list}
            </div>
        );
        //return (
        //    <div>
        //        <select>
        //            {options}
        //        </select>
        //        <pre>{JSON.stringify(this.state.virtualprofiles, null, 2)}</pre>
        //    </div>
        //);
    }

    _renderShowMemberModal() {
        return (
            <ContactInfo contact={this.state.showmember}/>
        );
    }
    _renderModalContent() {
        if (this.state.insertVirtualProfile) {
            return this._renderInsertVirtualProfileModal();
        } else if (this.state.showmember) {
            return this._renderShowMemberModal();
        }
        return null;
    }

    _renderButtons() {
        let buttons = [];
        buttons.push(
            <Grid item xs={4} style={{paddingLeft: 0}} key={'button1'}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={()=>{this._getConnectionReport();}}
                    >
                        {'Get members as Excel'}
                    </Button>
            </Grid>
        );
        if (this.state.groupid) {
            buttons.push(
                <div key={'button0'}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={()=>{this._insertVirtualProfile();}}
                    >
                        {'Add contact'}
                    </Button>
                </div>
            );

        } else {
            buttons.push(
                <Grid item xs={3} style={{paddingLeft: 0}}>
                    <div key={'button0'}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={()=>{this._leaveGroup();}}
                        >
                            {'Leave group'}
                        </Button>
                    </div>
                </Grid>
            );
        }
        return buttons;
    }

    render() {
        var buttons = this._renderButtons();
        var members = (<Loader
            type="ThreeDots"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={3000} //3 secs
    
         />);
        if (!this.state.loading) {
            members = this._renderMembers();
        }
        return (
            <Container component="main" align="center" maxWidth="md">
                <Modal
                    isOpen={this.state.modalVisible}
                    onRequestClose={this._closeModal}
                    style={{
                        content: {
                            top: '10%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            maxHeight: '80%',
                            transform: 'translateX(-50%)'
                        }
                    }}
                    contentLabel="Example Modal"
                >
                    <div style={{"display": "flex", "flexFlow":"row-reverse", "justifyContent":"space-around"}}>
                        <div style={{position: 'absolute', right:0, top: 0}}>
                            <IconButton
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={this._closeModal}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>

                        <div>
                            {this._renderModalContent()}
                        </div>
                    </div>
                </Modal>
                <h1 style={{color: 'white'}}>{this.state.groupname}</h1>
                <Paper >
                    {members}
                </Paper>
                <Grid container spacing={3} style={{marginTop: 10}}>
                    <Grid item xs={5} style={{paddingRight: 0}}></Grid>
                    {buttons}
                </Grid>
            </Container>
        );
    }
}

export default GroupMembers;

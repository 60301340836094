import React, { Component } from 'react';
import { connect } from 'react-redux';
import NetworkService from '../../../services/Networkservice';
import Container from '@material-ui/core/Container';
import {Paper} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

const mapStateToProps = function (state) {
    return {
        session: state.session
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShare: (share) => dispatch({
            type: 'SET_SHARE',
            share: share,
        }),
        setProfile: (profile) => dispatch({
            type: 'SET_PROFILE',
            profile: profile,
        }),
    };
};

class ShareHistory extends Component {   
    constructor(props) {
        super(props);
        this.state = {
            shares: [],
            list: [],
        } 
        this._handleShareList = this._handleShareList.bind(this);
        this._handleError = this._handleError.bind(this);
        this._updateShareHistoryList = this._updateShareHistoryList.bind(this);       
    }
    componentDidMount() {
        this._updateShareHistoryList();
    }

    _updateShareHistoryList() {
        NetworkService.getShares(
            this.props.session,
            this._handleShareList,
            this._handleError
        );
    }
    
    _handleShareList(shares) {
        var promises = [];
        var profiles = [];
        for(var i = 0; i < shares.length; i++){
            if (typeof profiles[shares[i].profileid] === 'undefined') {
                promises.push(NetworkService.getProfile(shares[i].profileid, this.props.session));
                profiles[shares[i].profileid] = {};
            }
        }

        Promise.all(promises).then(values=>{
            for(var i = 0; i < shares.length; i++){
                for(var j = 0; j < values.length; j++){
                    if (shares[i].profileid === values[j].id) {
                        shares[i].profile = values[j];
                    }
                }
            }
            this.setState({ shares: shares }, function(){
                this._buildList();
            })
        }).catch((err)=>{
            console.log(err);
        })
    }
    _handleError(error) {
       console.log("Error in getting active shares list");
    }

    _buildList() {
        let users = [];
        for (let i = 0; i < this.state.shares.length; i++) {
            let share = this.state.shares[i];
            if (!share.profile.surname) {
                share.profile.surname = '';
            }
            if (!share.profile.givenname) {
                share.profile.givenname = '';
            }
            users.push(share);
        }


        users.sort(function (a, b) {
            if (a.profile.givenname.toLowerCase() < b.profile.givenname.toLowerCase()) return -1;
            if (a.profile.givenname.toLowerCase() > b.profile.givenname.toLowerCase()) return 1;
            if (a.profile.surname.toLowerCase() < b.profile.surname.toLowerCase()) return -1;
            if (a.profile.surname.toLowerCase() > b.profile.surname.toLowerCase()) return 1;
            if (a.profile.nickname.toLowerCase() < b.profile.nickname.toLowerCase()) return -1;
            if (a.profile.nickname.toLowerCase() > b.profile.nickname.toLowerCase()) return 1;
            return 0;
        });

        let list = [];        
        users.forEach(function (entry) {
            let letter;
                if(entry.profile['givenname']){
                    letter = entry.profile['givenname'];
                } else {
                    letter = entry.profile['nickname'];
                }
                // if "chinese" character
                if (letter.match(/[\u2E80-\u2FD5\u3190-\u319f\u3400-\u4DB5\u4E00-\u9FCC]/)) {
                    letter = '...';
                }
                if (typeof list[letter] == 'undefined') {
                    list[letter] = [];
                }
                list[letter].push(entry);

        });

        let grouplist = [];

        for (let key in list) {
            if (list.hasOwnProperty(key)) {
                grouplist.push({ displayname: key, itemdivider: true, last: false });
                let clist = list[key];
                let sublist = [];
                // reverse order (lastest first) for share list
                for (let i = 0; i < clist.length; i++) {
                    clist[i]['itemdivider'] = false;
                    sublist.unshift(clist[i]);
                }
                // for some reason grouplist.concat wont work so we do it manually
                for (let i = 0; i < sublist.length; i++){
                    grouplist.push(sublist[i]);
                }
            }
        }
        this.setState({ list: grouplist });
    }

    _goToShare(share) {     
        this.props.setShare(share);        
        this.props.history.push({
            pathname: '/ShareView',
            state: { guid: share.guid }
        })
    }

    render() {            
        return (
            <Container component="main" align="center" maxWidth="md">
            <Paper style={{margin: 20, maxHeight: 500, overflow: 'auto'}} >
            <List>
                {this.state.list.map((item, i) => {                    
                    if (item.itemdivider) {
                        return (
                            <div key={i}>
                                <ListItem key={i}>{item.displayname}</ListItem>
                                <Divider component="li"/> 
                            </div>
                        )
                    } else {
                        return (
                            
                            <div key={i}>
                                <ListItem key={i} onClick={() => this._goToShare(item)} >{item.sharename}</ListItem>
                                <Divider component="li"/> 
                            </div>
                        );
                    }                   
                })}
            </List>
            </Paper>
            </Container>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ShareHistory);

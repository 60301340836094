const group = (state = [], action) => {
    switch (action.type) {
        case 'SET_GROUP':
            return ({
                id: action.group.id,
                displayname: action.group.displayname,
                givenname: action.group.givenname,
                surname: action.group.surname,
                nickname: action.group.nickname,
                company: action.group.company,
                position: action.group.position,
                phone: action.group.phone,
                email: action.group.email,
                street: action.group.street,
                postcode: action.group.postcode,
                city: action.group.city,
                country: action.group.country,
                website: action.group.website,
                image: action.group.image,
                profiledata: action.group.profiledata,
                guid: action.group.guid,
                filterid: action.group.filterid,
                image_guid: action.group.image_guid,
            })
        default:
            return state
    }
}

export default group
import React, { Component } from 'react';
import { connect } from 'react-redux'
import Container from '@material-ui/core/Container';
import Style from './About.css';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import logo from '../../../images/k_logo_trans_white_large.png';
import nsLogo from '../../../images/newseed_logo.png';
import Button from '@material-ui/core/Button';


const mapStateToProps = function (state) {
    return {
        session: state.session
    }
};

const mapDispatchToProps = (dispatch) => {
    return {        
        setShare: (share) => dispatch({
            type: 'SET_SHARE',
            share: share,
        }),
    };
};

const message = "I K for Contact delar du din information enkelt och säkert. Här får du tillgång till alla dina kontakters senaste uppgifter. Du har full kontroll över vilken information du har delat och till vem. När som helst kan du ändra, uppdatera eller ta bort uppgifter. Så att dina kontakter alltid har rätt uppgifter till dig."
const newSeedMessage = "NewSeed is an innovative IT-consultant from Sweden. K for Contact was created in December 2017 as a contribution to TechCrunch Hackathon in Berlin. Read more about NewSeed on the website."

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
        
    }

    render() {
        return (
          <div className="root">
            <Container maxWidth="sm" style={{marginTop: 50, marginBottom: 50}}>
              <Typography component="h1" variant="h2" align="center" style={{color: '#fff'}} gutterBottom>
                K for Contact
              </Typography>
              <Typography variant="h5" align="center" style={{color: '#fff'}} paragraph>
                {message}
              </Typography>
              </Container>
              <Container width={1} style={{ bottom: 0, backgroundColor: "#fff", marginTop: 20, marginBottom: 0}} align="center">
                <div>
                  <img src={nsLogo} alt="Logo" style={{width: 200, marginBottom: 20, marginTop: 20}} />
                  <Typography component="h1" variant="subtitle1" align="center" gutterBottom>
                    NewSeed - Realising Ideas
                  </Typography>
                  <Typography  variant="body2" align="left" style={{maxWidth: 400, fontFamily: "Helvetica"}} paragraph>
                    {newSeedMessage}
                  </Typography>
                  <Button variant="contained" style={{backgroundColor:"#3788D0", color: "#fff", marginBottom: 40}} href="https://newseed.se" target="_blank">
                    NewSeed IT Solutions
                  </Button>
                </div>
              </Container>
          </div>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)((About))

const filter = (state = [], action) => {
    switch (action.type) {
        case 'SET_FILTER':
            return ({
                id: action.filter.id,
                guid: action.filter.guid,
                name: action.filter.name,
                displayname: action.filter.displayname,
                givenname: action.filter.givenname,
                surname: action.filter.surname,
                nickname: action.filter.nickname,
                company: action.filter.company,
                position: action.filter.position,
                phone: action.filter.phone,
                email: action.filter.email,
                street: action.filter.street,
                postcode: action.filter.postcode,
                city: action.filter.city,
                country: action.filter.country,
                website: action.filter.website,
                image: action.filter.image,
                filterxprofiledata: action.filter.filterxprofiledata,
            })
        default:
            return state
    }
}

export default filter